import { useCallback } from "react";
import { useController } from "react-hook-form";


import { DynamicFormField } from "./DynamicForm";
import { PhotoActions } from "./PhotoActions";
import { AnyFile, UploadInput, UploadInputBasicProps } from "./UploadInput";

export type PrimaryImage = UploadInputBasicProps & {
  input: "PRIMARY_IMAGE";
};

type FormPrimaryImageInputProps = Omit<DynamicFormField, "type"> & {
  type: Omit<PrimaryImage, "input">;
};

export const FormPrimaryImageInput = (props: FormPrimaryImageInputProps) => {
  const {
    field: { value, onChange },
  } = useController({
    name: props.name,
  });

  const handleSetAsPrimary = useCallback(
    (photoId: number) => {
      const files = value as AnyFile[];
      const updatedPhotos = files.map((file) => {
        if (typeof file === "string") {
          return file;
        }
        return {
          ...file,
          primary_photo: file.photo_id === photoId,
        };
      });
      onChange(updatedPhotos);
    },
    [value, onChange],
  );

  const handleDelete = useCallback(
    (photoId: number) => {
      const files = value as AnyFile[];
      const updatedPhotos = files.filter(
        (f) => typeof f === "string" || f.photo_id !== photoId,
      );
      onChange(updatedPhotos);
    },
    [value, onChange],
  );

  const renderAction = useCallback(
    (file: AnyFile) => {
      if (typeof file !== "string" && file.photo_id) {
        return (
          <PhotoActions
            asDropdown
            file={
              file as AnyFile & {
                photo_id: number;
                primary_photo: boolean;
              }
            }
            onSetAsPrimary={handleSetAsPrimary}
            onDelete={() => handleDelete(file.photo_id as number)}
          />
        );
      }
      return null;
    },
    [handleSetAsPrimary, handleDelete],
  );

  return (
    <UploadInput
      files={value}
      onChange={onChange}
      renderAction={renderAction}
      {...props.type}
    />
  );
};

export type MultiUpload = UploadInputBasicProps & {
  input: "MULTI_UPLOAD";
};

type FormMultiUploadInputProps = Omit<DynamicFormField, "type"> & {
  type: Omit<MultiUpload, "input">;
  asDropdown?: boolean;
};

export const FormMultiUploadInput = (props: FormMultiUploadInputProps) => {
  const { field } = useController({
    name: props.name,
  });
  return (
    <UploadInput
      files={field.value as AnyFile[]}
      onChange={field.onChange}
      {...props.type}
    />
  );
};

export type SingleUpload = Omit<UploadInputBasicProps, "maxFiles"> & {
  input: "SINGLE_UPLOAD";
};

type FormSingleUploadInputProps = Omit<DynamicFormField, "type"> & {
  type: Omit<SingleUpload, "input">;
};

export const FormSingleUploadInput = (props: FormSingleUploadInputProps) => {
  const { field } = useController({ name: props.name });
  return (
    <UploadInput
      files={field.value ? [field.value] : []}
      onChange={(files) => {
        console.log({ files });
        field.onChange(files?.[0] || "");
      }}
      maxFiles={1}
      {...props.type}
    />
  );
};

export type CameraValue = {
  url: string;
  photo_id: number;
  status?: "UPLOADING" | "UPLOADED" | "ERROR";
};
