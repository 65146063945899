import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

import { localStorageKeys } from "@/lib/constants";

export const dynamicFormPreferences = {
  labelsOnSide: {
    icon: "fa-heading",
    label: "Labels on side",
    key: "labelsOnSide",
  },
  emphasiseLabels: {
    icon: "fa-bold",
    label: "Emphasise labels",
    key: "emphasiseLabels",
  },
  reducePadding: {
    icon: "fa-circle-info",
    label: "Reduce padding",
    key: "reducePadding",
  },
  showHelpText: {
    icon: "fa-arrows-left-right-to-line",
    label: "Show help text",
    key: "showHelpText",
  },
};

const defaultFormPreferences: Record<
  keyof typeof dynamicFormPreferences,
  boolean
> = {
  labelsOnSide: true,
  emphasiseLabels: true,
  reducePadding: true,
  showHelpText: true,
  // HIGHLIGHT_REQUIRED_FIELDS: true,
  // HIGHLIGHT_EMPTY_FIELDS: false,
};

// Define store state and actions
type StoreTypes = {
  // State
  labelsOnSide: boolean;
  emphasiseLabels: boolean;
  reducePadding: boolean;
  showHelpText: boolean;

  setPreference: (
    preferenceName: keyof typeof dynamicFormPreferences,
    value: boolean,
  ) => void;
};

// Create the store
export const useRecordPreferenceStore = create<StoreTypes>()(
  persist(
    (set) => ({
      // Initial state
      labelsOnSide: defaultFormPreferences.labelsOnSide,
      emphasiseLabels: defaultFormPreferences.emphasiseLabels,
      reducePadding: defaultFormPreferences.reducePadding,
      showHelpText: defaultFormPreferences.showHelpText,

      // Actions
      setPreference: (key, value) => {
        set((state) => {
          return {
            ...state,
            [key]: value,
          };
        });
      },
    }),
    {
      name: localStorageKeys.FORM_PREFERENCES,
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
