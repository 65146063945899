import { AuditStatusBadge } from "@/components/AuditStatusBadge";
import { getPath } from "@/lib/utils";
import { AuditStatus, AuditStatusId } from "@/types";

import { RenderCellProps } from "./cell-types";

export const AuditBadgeCellComponent = <T extends object>({
  row,
  accessorKey,
  cellInfo,
}: RenderCellProps<T>) => {
  if (cellInfo.type !== "AUDIT_BADGE") {
    return null;
  }
  const status = getPath(row.original, accessorKey) as AuditStatusId;
  return <AuditStatusBadge status={AuditStatus[status]} />;
};
