import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

// Define types
export type PreviousTableDataType = {
  totalCount: number;
  objectId: number | string;
  routeKey: string;
  currentPage: number;
  perPage: number;
};

export type PreviousTableRouteObjectType = {
  routeName: string;
  routeParams: Record<string, unknown>;
};

// Define store state and actions
type TableNavigationState = {
  // State
  previousTableData: PreviousTableDataType[] | null;
  previousTableRouteObject: PreviousTableRouteObjectType | null;

  // Actions
  setPreviousTableData: (value: PreviousTableDataType[]) => void;
  setPreviousTableRouteObject: (
    value: PreviousTableRouteObjectType | null,
  ) => void;

  // For direct state manipulation if needed
  updateTableDataItem: (
    index: number,
    updatedItem: Partial<PreviousTableDataType>,
  ) => void;
  addTableDataItem: (item: PreviousTableDataType) => void;
  removeTableDataItem: (objectId: number) => void;
  clearTableData: () => void;
};

// Create the store
export const useTableNavigationStore = create<TableNavigationState>()(
  persist(
    (set) => ({
      // Initial state
      previousTableData: null,
      previousTableRouteObject: null,

      // Actions
      setPreviousTableData: (value) => set({ previousTableData: value }),
      setPreviousTableRouteObject: (value) =>
        set({ previousTableRouteObject: value }),

      // Additional utility actions
      updateTableDataItem: (index, updatedItem) =>
        set((state) => {
          if (!state.previousTableData) return state;

          const newData = [...state.previousTableData];
          newData[index] = { ...newData[index], ...updatedItem };

          return { previousTableData: newData };
        }),

      addTableDataItem: (item) =>
        set((state) => {
          const currentData = state.previousTableData || [];
          return { previousTableData: [...currentData, item] };
        }),

      removeTableDataItem: (objectId) =>
        set((state) => {
          if (!state.previousTableData) return state;

          return {
            previousTableData: state.previousTableData.filter(
              (item) => item.objectId !== objectId,
            ),
          };
        }),

      clearTableData: () => set({ previousTableData: null }),
    }),
    {
      name: "table-navigation-storage",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
