import { AuditAssetMenu } from "@/components/AuditAssetsDropdownMenu";
import { AuditRow } from "@/types";

import { RenderCellProps } from "./cell-types";

export const AuditAssetDropdownMenuCellComponent = <T extends object>({
  row,
  cellInfo,
}: RenderCellProps<T>) => {
  if (cellInfo.type !== "AUDIT_ASSET_DROPDOWN_MENU") {
    return null;
  }
  return <AuditAssetMenu audit={row.original as AuditRow} />;
};
