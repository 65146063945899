import React from "react";

import { cn } from "@/lib/utils";

const ErrorHelperText = (props: { error?: string; helpText?: string }) => {
  return (
    <p
      className={cn(
        "text-[0.8rem] text-muted-foreground",
        props.error && "text-destructive",
      )}
    >
      {props.error ? props.error : props.helpText}
    </p>
  );
};

export { ErrorHelperText };
