//#region
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  ColumnDef,
  Row,
  SortingState,
  Updater,
  flexRender,
  functionalUpdate,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import get from "lodash/get";
import set from "lodash/set";
import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { tinykeys } from "tinykeys";

import { Icon } from "@/components/Icon";
import { DataTablePagination } from "@/components/Pagination";
import { DataTableExport } from "@/components/data-table/data-table-export";
import {
  RowDragHandleCell,
  SortableTableRow,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadRow,
  TableHeader,
  TableRow,
} from "@/components/ui";
import { TableName, exportableTableNames } from "@/lib/constants";
import { generateColumnDefs } from "@/lib/generate-column-defs";
import { cn } from "@/lib/utils";
import { PaginatedResource } from "@/types";

import { filterColumnCallback } from "./data-table-utils";
import { DisplayOptions } from "./display-options";
import { DataTableFilters } from "./filters";
import {
  DataTableRowSelectionProps,
  TableSelectionToolbar,
  useRowSelection,
  useRowSelectionColumn,
} from "./row-selection";
import { SearchInput } from "./search-input";
import { useTableConfig } from "./table-config-store";

//#endregion

export type CommonDataTablePropsExport = (typeof exportableTableNames)[number];
export type CommonDataTablePropsExportParams = Record<string, string>;

export type CommonDataTableProps<TData, TValue> = {
  tableName: TableName;
  extraActions?: ReactNode;
  selection?: DataTableRowSelectionProps<TData>;
  selectedRow?: string;
  rowClassName?: (row: Row<TData>) => string;
  onRowClick?: (row: Row<TData>) => void;
  dndSort?: {
    enabled: boolean;
    disabledMessage?: string;
    sortKey: string;
    onChange: (data: TData[]) => Promise<void>;
  };
  prefixColumns?: ColumnDef<TData, TValue>[];
  actionColumns?: ColumnDef<TData, TValue>[];
  getRowId?: (originalRow: TData, index: number, parent?: Row<TData>) => string;
  export?: CommonDataTablePropsExport;
  exportParams?: CommonDataTablePropsExportParams;
};

export type DataTableProps<TData, TValue> = CommonDataTableProps<
  TData,
  TValue
> & {
  tableData: PaginatedResource<TData>;
};

export const SimpleDataTable = <TData extends object, TValue>(
  props: DataTableProps<TData, TValue>,
) => {
  const [_data, _setData] = useState<TData[]>(props.tableData.data);
  useEffect(() => {
    if (props.tableData.data) {
      _setData(props.tableData.data);
    }
  }, [props.tableData.data, _setData]);
  const page = useTableConfig((state) => state.page);
  const limit = useTableConfig((state) => state.limit);
  const order_by = useTableConfig((state) => state.order_by);
  const order_direction = useTableConfig((state) => state.order_direction);
  const columnVisibility = useTableConfig((state) => state.columnVisibility);
  const columnOrder = useTableConfig((state) => state.columnOrder);
  const setOrder = useTableConfig((state) => state.setOrder);
  const setPage = useTableConfig((state) => state.setPage);

  //#region - DND
  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {}),
  );

  const dndSortColumns = useMemo(() => {
    if (!props.dndSort) {
      return [];
    }

    const isDndEnabled = () => {
      if (!props.dndSort) {
        return false;
      }
      if (
        order_by === props.tableData.defaultOrderBy &&
        order_direction === props.tableData.defaultOrderDirection
      ) {
        return props.dndSort.enabled;
      }
      return false;
    };

    const dragAndDropDisabled = !isDndEnabled();

    return [
      {
        id: "drag-handle",
        header: "",
        cell: ({ row }: { row: Row<TData> }) => (
          <RowDragHandleCell
            rowId={row.id}
            disabled={dragAndDropDisabled}
            disabledMessage={props.dndSort?.disabledMessage}
          />
        ),
      },
    ];
  }, [
    order_by,
    order_direction,
    props.dndSort,
    props.tableData.defaultOrderBy,
    props.tableData.defaultOrderDirection,
  ]);
  const onDragEnd = (event: DragEndEvent) => {
    if (!props.dndSort) {
      return;
    }

    const { active, over } = event;
    if (active && over && active.id !== over.id && rows && dataIds) {
      const activeIndex = dataIds.indexOf(active.id as string);
      const overIndex = dataIds.indexOf(over.id as string);

      // If active or over id is not found, return early
      if (activeIndex === -1 || overIndex === -1) {
        return;
      }

      const updatedRows = arrayMove(rows, activeIndex, overIndex);

      setRows([...updatedRows]);

      // Find the range of indices affected by the move
      const startIndex = Math.min(activeIndex, overIndex);
      const endIndex = Math.max(activeIndex, overIndex);

      const fallbackValue = (page - 1) * limit;

      // Use lodash get to safely access nested property
      let startIndexSequence = Number(
        startIndex > 0
          ? get(
              updatedRows[startIndex - 1].original,
              props.dndSort.sortKey,
              fallbackValue,
            )
          : fallbackValue,
      );

      // Recalculate sequence numbers only for rows within this range
      for (let i = startIndex; i <= endIndex; i++) {
        // Use lodash set to safely set nested property
        set(
          updatedRows[i].original,
          props.dndSort.sortKey,
          String(startIndexSequence + 1),
        );
        startIndexSequence++;
      }

      // Prepare the data to be passed to the onChange callback
      const updatedData = updatedRows.map((row) => row.original);

      // Update the state with the new data
      _setData(updatedData);

      props.dndSort.onChange(updatedData);
    }
  };
  //#endregion

  const { selectionColumn } = useRowSelectionColumn(props.selection);
  const columns = useMemo(() => {
    const uniqueTableColumns = props.tableData.columns.filter(
      (column, index, self) =>
        index === self.findIndex((c) => c.header === column.header),
    );
    return [
      ...(selectionColumn ? [selectionColumn] : []),
      ...dndSortColumns,
      ...(props.prefixColumns || []),
      ...generateColumnDefs<TData>(uniqueTableColumns),
      ...(props.actionColumns || []),
    ];
  }, [
    props.tableData.columns,
    selectionColumn,
    dndSortColumns,
    props.prefixColumns,
    props.actionColumns,
  ]);

  const sorting: SortingState = [
    { id: order_by, desc: order_direction !== "asc" },
  ];

  const setSorting = (sortUpdater: Updater<SortingState>) => {
    const newSorting = functionalUpdate(sortUpdater, sorting);
    if (!newSorting[0]) {
      return;
    }
    const new_order_by = newSorting[0].id;
    const new_order_direction = newSorting[0].desc ? "desc" : "asc";
    setOrder({
      order_by: new_order_by,
      order_direction: new_order_direction,
    });
  };

  // Define pinned column IDs in a constant - easier to maintain.
  // Plan to use this to tie in pin to left when we start supporting it soon
  const PINNED_LEFT_COLUMN_IDS = ["drag-handle", "select"];

  // Then in your component, compute the actual pinned columns based on what's available
  const getActualPinnedColumns = () => {
    return PINNED_LEFT_COLUMN_IDS.filter((id) => {
      // Only include 'select' if selection is enabled and column exists
      if (id === "select") {
        return selectionColumn !== null;
      }
      // Only include 'drag-handle' if dndSort present
      if (id === "drag-handle") {
        return typeof props.dndSort !== "undefined";
      }
      // Add similar conditions for any future pinned columns
      return false;
    });
  };

  const table = useReactTable({
    data: _data,
    columns,
    enableRowSelection: props.selection?.enabled,
    getCoreRowModel: getCoreRowModel(),
    manualSorting: true,
    onSortingChange: setSorting,
    state: {
      sorting,
      columnVisibility: columnVisibility,
      columnOrder: [
        // First add actually present pinned columns
        ...getActualPinnedColumns(),
        // Then add the rest, filtering out any that are already included
        ...columnOrder.filter(
          (columnId) => !getActualPinnedColumns().includes(columnId),
        ),
      ],
    },
    enableSortingRemoval: false,
    getRowId: props.getRowId,
  });

  const { isSelectionMode } = useRowSelection(props.selection, table);
  const dataColumns = table.getAllLeafColumns().filter(filterColumnCallback);
  const exportColumns = dataColumns
    .filter(
      (column) =>
        column.getIsVisible() &&
        !props.actionColumns?.find(
          (actionColumn) =>
            actionColumn.header === (column.columnDef.header as string),
        ),
    )
    .map((column) => column.columnDef.header as string);

  const tableRows = table.getRowModel().rows;
  const [rows, setRows] = useState<Row<TData>[]>(tableRows);
  const dataIds = useMemo(() => rows.map((item) => item.id), [rows]);

  useEffect(() => {
    if (tableRows) {
      setRows(tableRows);
    }
  }, [tableRows]);

  const [wrapText] = useState<boolean>(() => {
    return localStorage.getItem("wrapText") === "true";
  });

  const searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const unsubscribe = tinykeys(window, {
      Slash: (event) => {
        const target = event.target as HTMLElement;
        const isInInput =
          target.tagName === "INPUT" ||
          target.tagName === "TEXTAREA" ||
          target.getAttribute("contenteditable") === "true" ||
          target.closest('[contenteditable="true"]') !== null;
        if (!isInInput) {
          event.preventDefault();
          event.stopPropagation();
          if (searchInputRef.current) {
            searchInputRef.current.focus();
          }
        }
      },
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div aria-label="data table container" className="flex-1 overflow-hidden">
      <div className="flex h-full flex-col">
        <div className="p-4">
          <div className="flex flex-row justify-between">
            <div>
              <DataTableFilters />
            </div>
            <div className="flex flex-shrink-0 space-x-2">
              <TableSelectionToolbar
                selection={props.selection}
                selectionState={table.getState().rowSelection}
                setSelectionState={table.setRowSelection}
              />
              {props.extraActions}
              {props.export && (
                <DataTableExport
                  export={props.export}
                  exportParams={props.exportParams}
                  columns={exportColumns}
                />
              )}
              <div className="inline-flex">
                <DisplayOptions />
              </div>
              <SearchInput />
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col overflow-hidden rounded-md">
          <DndContext
            collisionDetection={closestCenter}
            modifiers={[restrictToVerticalAxis]}
            onDragEnd={onDragEnd}
            sensors={sensors}
          >
            <Table className="border-separate border-spacing-x-0">
              <TableHeader>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableHeadRow key={headerGroup.id} className="group/row">
                    {headerGroup.headers.map((header) => {
                      const shouldGetSize =
                        header.id === "select" ||
                        header.id === "drag-handle" ||
                        header.id === "actions" ||
                        header.id.startsWith("action");
                      return (
                        <TableHead
                          scope="col"
                          key={header.id}
                          onClick={header.column.getToggleSortingHandler()}
                          style={{
                            width: shouldGetSize ? 34 : undefined,
                            minWidth: shouldGetSize ? 34 : undefined,
                            maxWidth: shouldGetSize ? 34 : undefined,
                          }}
                          className="border-[0.5px] border-transparent"
                        >
                          {header.isPlaceholder ? null : (
                            <span className="flex flex-row items-center">
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext(),
                              )}
                              {{
                                asc: (
                                  <Icon
                                    icon="fa-angle-up"
                                    className="ml-1 text-xs"
                                  />
                                ),
                                desc: (
                                  <Icon
                                    icon="fa-angle-down"
                                    className="ml-1 text-xs"
                                  />
                                ),
                              }[header.column.getIsSorted() as string] ?? null}
                              {!header.column.getIsSorted() &&
                                header.column.getCanSort() && (
                                  <Icon
                                    icon="fa-angles-up-down"
                                    className="ml-1 text-xs"
                                  />
                                )}
                            </span>
                          )}
                        </TableHead>
                      );
                    })}
                  </TableHeadRow>
                ))}
              </TableHeader>
              <TableBody>
                {rows?.length ? (
                  <SortableContext
                    items={dataIds as UniqueIdentifier[]}
                    strategy={verticalListSortingStrategy}
                  >
                    {rows.map((row) => (
                      <SortableTableRow
                        key={row.id}
                        rowKey={row.id}
                        data-state={row.getIsSelected() && "selected"}
                        className={cn(
                          props.rowClassName?.(row),
                          props.onRowClick
                            ? "group/row cursor-pointer"
                            : "group/row",
                          row.getIsSelected() &&
                            "bg-blue-100 transition-colors duration-100 hover:bg-blue-150",
                          // TODO - use props.rowClassName
                          props.getRowId &&
                            props.selectedRow &&
                            props.selectedRow === row.id
                            ? "bg-blue-150 transition-colors duration-100"
                            : "",
                        )}
                        onClick={(e) => {
                          if (isSelectionMode) {
                            const enabled = props.selection?.isRowEnabled(
                              row.original,
                            );
                            if (enabled) {
                              row.toggleSelected();
                            }
                            return;
                          }
                          if (
                            props.onRowClick &&
                            (e.nativeEvent.target instanceof
                              HTMLTableCellElement ||
                              (e.nativeEvent.target instanceof HTMLElement &&
                                e.nativeEvent.target.tagName === "MARK"))
                          ) {
                            props.onRowClick(row);
                          }
                        }}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <TableCell
                            key={cell.id}
                            className={cn(
                              "border-[0.5px] border-x-gray-50 border-y-gray-100 px-2 py-1",
                              !wrapText && "max-w-72 truncate",
                            )}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </TableCell>
                        ))}
                      </SortableTableRow>
                    ))}
                  </SortableContext>
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={columns.length}
                      className="h-24 text-center"
                    >
                      No results.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </DndContext>
        </div>
        <DataTablePagination
          {...props.tableData.meta}
          onLinkClick={(link) => {
            setPage(
              Number(Object.fromEntries(new URL(link.url).searchParams).page),
            );
          }}
        />
      </div>
    </div>
  );
};
