//#region Imports
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  restrictToFirstScrollableAncestor,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { router } from "@inertiajs/react";
import { ColumnOrderState, VisibilityState } from "@tanstack/react-table";
import { useState } from "react";
import isEqual from "react-fast-compare";

import { usePageData } from "@/hooks/usePageData";
import { cn } from "@/lib/utils";

import { Icon } from "../Icon";
import { PreferenceSwitch } from "../PreferenceSwitch";
import { SortableDisplayMenuCheckboxItem } from "../SortableDropdownMenu";
import {
  Button,
  DropdownButtonIconLeft,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  IconButton,
  Label,
  ScrollArea,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui";
import { useTableConfig } from "./table-config-store";

//#endregion

export type DisplayOptionsParams = {
  search: string;
  order_by: string;
  order_direction: string;
  limit: number;
  page: number;
  displayFlags: Record<string, "true" | "false">;
  columnVisibility: VisibilityState;
  columnOrder: ColumnOrderState;
};

export const DisplayOptions = () => {
  const {
    platform: { translations },
  } = usePageData();

  const [wrapText, setWrapText] = useState<string | null>(() => {
    return localStorage.getItem("wrapText");
  });
  const sortableColumns = useTableConfig((state) => state.sortableColumns);
  const setOrder = useTableConfig((state) => state.setOrder);
  const order_by = useTableConfig((state) => state.order_by);
  const defaultOrderBy = useTableConfig((state) => state.defaultOrderBy);
  const order_direction = useTableConfig((state) => state.order_direction);
  const limitOptions = useTableConfig((state) => state.pageLimits);
  const limit = useTableConfig((state) => state.limit);
  const setLimit = useTableConfig((state) => state.setLimit);

  const orderBy = order_by || defaultOrderBy;
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <DropdownButtonIconLeft icon={"fa-sliders"} variant="secondary">
          {translations.display}
        </DropdownButtonIconLeft>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        data-testid="display-options-menu"
        align="end"
        className="w-96"
      >
        <DropdownMenuLabel className="flex flex-row items-center justify-between space-x-2">
          <Label className={"w-16 shrink-0"}>{translations.order_by}</Label>
          <div className="inline-flex flex-1 items-center gap-2">
            <Select
              defaultValue={order_by}
              onValueChange={(column) => {
                setOrder({ order_by: column });
              }}
              disabled={sortableColumns.length === 0}
            >
              <SelectTrigger>
                <SelectValue placeholder="None" />
              </SelectTrigger>
              <SelectContent>
                {sortableColumns.map((column) => {
                  return (
                    <SelectItem key={column.id} value={column.header}>
                      {column.header}
                    </SelectItem>
                  );
                })}
              </SelectContent>
            </Select>
            {orderBy && (
              <IconButton
                className={"shrink-0"}
                variant={"tertiary"}
                icon={
                  order_direction === "asc" || !order_direction
                    ? "fa-arrow-down-short-wide"
                    : "fa-arrow-down-wide-short"
                }
                onClick={() => {
                  const newOrderDirection =
                    order_direction === "asc" || !order_direction
                      ? "desc"
                      : "asc";
                  setOrder({ order_direction: newOrderDirection });
                }}
              />
            )}
          </div>
        </DropdownMenuLabel>
        <DropdownMenuLabel className="flex flex-row items-center justify-between space-x-2">
          <Label className={"w-16 shrink-0"}>{translations.per_page}</Label>
          <div className="inline-flex flex-1">
            <Select
              defaultValue={String(limit)}
              onValueChange={(value) => {
                setLimit(Number(value));
              }}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select" />
              </SelectTrigger>
              <SelectContent>
                {limitOptions.map((limit) => (
                  <SelectItem key={limit} value={String(limit)}>
                    {limit}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DataTableDisplayFlagsToggle />
        <DropdownMenuLabel className="flex items-center justify-between">
          <PreferenceSwitch
            label={"Wrap text"}
            checked={wrapText === "true"}
            onChange={(checked) => {
              setWrapText(checked ? "true" : "false");
              localStorage.setItem("wrapText", checked ? "true" : "false");
              window.location.reload();
            }}
          />
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <ColumnVisibility />
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const DataTableDisplayFlagsToggle = () => {
  const displayFlags = useTableConfig((state) => state.displayFlags);
  const allDisplayFlags = useTableConfig((state) => state.allDisplayFlags);
  const toggleDisplayFlag = useTableConfig((state) => state.toggleDisplayFlag);
  if (!allDisplayFlags || !allDisplayFlags.length) {
    return null;
  }
  return (
    <>
      {allDisplayFlags.map((displayFlag) => {
        const selected = displayFlags[displayFlag.key] === "true";
        return (
          <DropdownMenuLabel
            className="flex items-center justify-between"
            key={displayFlag.key}
          >
            <PreferenceSwitch
              label={displayFlag.label}
              helperText={displayFlag.tooltip}
              checked={selected}
              onChange={(checked) =>
                toggleDisplayFlag(displayFlag.key, checked)
              }
            />
          </DropdownMenuLabel>
        );
      })}
    </>
  );
};

const ColumnVisibility = () => {
  const savePreferencesAs = useTableConfig((state) => state.savePreferencesAs);
  const columns = useTableConfig((state) => state.uniqueColumns);
  const columnVisibility = useTableConfig((state) => state.columnVisibility);
  const resetColumnDefaults = useTableConfig(
    (state) => state.resetColumnDefaults,
  );
  const defaultColumnVisibility = useTableConfig(
    (state) => state.defaultColumnVisibility,
  );
  const columnOrder = useTableConfig((state) => state.columnOrder);
  const defaultColumnOrder = useTableConfig(
    (state) => state.defaultColumnOrder,
  );
  const toggleColumnVisibility = useTableConfig(
    (state) => state.toggleColumnVisibility,
  );
  const resetColumnVisibility = useTableConfig(
    (state) => state.resetColumnVisibility,
  );
  const updateColumnOrder = useTableConfig((state) => state.updateColumnOrder);

  const isDefaultColumnVisibility = isEqual(
    columnVisibility,
    defaultColumnVisibility,
  );
  const isDefaultColumnOrder = isEqual(columnOrder, defaultColumnOrder);
  const canResetColumns = !isDefaultColumnVisibility || !isDefaultColumnOrder;

  const { auth } = usePageData();
  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {}),
  );

  const [defaultUpdated, setDefaultUpdated] = useState(false);

  const getColumnVisibility = (header: string) => {
    return columnVisibility[header] ?? true;
  };

  const onDragEnd = (event: DragEndEvent) => {
    const { delta, active, over } = event;
    if (!active) {
      return;
    }
    if (delta.y === 0) {
      const column = columns.find((c) => c.header === active.id);
      if (column) {
        toggleColumnVisibility(column);
      }
      return;
    }
    if (!over) {
      return;
    }
    if (active.id !== over.id) {
      const activeIndex = columnOrder.indexOf(active.id as string);
      const overIndex = columnOrder.indexOf(over.id as string);

      // If active or over id is not found, return early
      if (activeIndex === -1 || overIndex === -1) {
        return;
      }

      const updatedColumns = arrayMove(columnOrder, activeIndex, overIndex);
      updateColumnOrder(updatedColumns);
    }
  };

  return (
    <>
      <div className="flex w-full flex-row items-center justify-between">
        <Label className="px-1 text-sm font-medium">Column</Label>
      </div>
      <ScrollArea
        data-testid="column-visibility-scroll-area"
        className={`${columns.length > 8 ? "h-72" : ""} px-1`}
      >
        <DndContext
          collisionDetection={closestCenter}
          modifiers={[
            restrictToVerticalAxis,
            restrictToFirstScrollableAncestor,
          ]}
          onDragEnd={onDragEnd}
          sensors={sensors}
        >
          <SortableContext
            items={columnOrder}
            strategy={verticalListSortingStrategy}
          >
            {columnOrder.map((column) => {
              return (
                <SortableDisplayMenuCheckboxItem
                  rowKey={column}
                  key={column}
                  checked={getColumnVisibility(column)}
                >
                  <Icon
                    icon="fa-grip-vertical"
                    iconStyle="SOLID"
                    className="cursor-grab text-muted-foreground opacity-0 transition-opacity duration-75 group-hover:opacity-100"
                  />
                  <span>{column}</span>
                </SortableDisplayMenuCheckboxItem>
              );
            })}
          </SortableContext>
        </DndContext>
      </ScrollArea>
      {savePreferencesAs && !defaultUpdated && (
        <div
          className={cn(
            "flex flex-row justify-end transition-all",
            canResetColumns ? "h-auto opacity-100" : "h-0 opacity-0",
          )}
        >
          <Button variant={"tertiary"} onClick={resetColumnVisibility}>
            Reset
          </Button>
          <Button
            variant={"tertiary"}
            className={"text-primary"}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setDefaultUpdated(true);
              router.post(
                route("update-table-references"),
                {
                  user_uuid: auth.user.uuid,
                  table_name: savePreferencesAs,
                  columns: columnOrder.map((c) =>
                    getColumnVisibility(c) ? c : `-${c}`,
                  ),
                },
                {
                  only: ["non_existent_key_to_prevent_table_refresh"],
                  preserveState: true,
                  preserveScroll: true,
                  preserveUrl: true,
                  onSuccess: () => {
                    resetColumnDefaults();
                    setDefaultUpdated(false);
                  },
                },
              );
            }}
          >
            Set as default
          </Button>
        </div>
      )}
    </>
  );
};
