import {
  Column,
  ColumnOrderState,
  VisibilityState,
} from "@tanstack/react-table";

import { localStorageKeys } from "@/lib/constants";

export const filterColumnCallback = <T = unknown>(column: Column<T>) =>
  !column.id.startsWith("action") &&
  column.id !== "actions" &&
  column.id !== "drag-handle" &&
  column.id !== "select";

type TableSettings = Record<
  string,
  {
    columnVisibility: VisibilityState;
    columnOrder: ColumnOrderState;
    displayFlags: Record<string, string>;
  }
> & {
  limit?: string;
};

export const getGlobalTableSettingsFromStorage = ({
  settingsName,
}: {
  settingsName: "limit";
}) => {
  const allTableSettings = localStorage.getItem(localStorageKeys.TABLE_SETTING);
  if (allTableSettings) {
    const parsedTableSettings = JSON.parse(allTableSettings);
    return parsedTableSettings[settingsName];
  }
};

export const setGlobalTableSettingsInStorage = ({
  settingsName,
  settings,
}: {
  settingsName: "limit";
  settings: string;
}) => {
  let allTableSettings: TableSettings = {};
  const allTableSettingsString = localStorage.getItem(
    localStorageKeys.TABLE_SETTING,
  );
  if (allTableSettingsString) {
    allTableSettings = JSON.parse(allTableSettingsString);
  }
  allTableSettings[settingsName] = settings;
  localStorage.setItem(
    localStorageKeys.TABLE_SETTING,
    JSON.stringify(allTableSettings),
  );
};

export const getSettingsFromStorage = ({
  tableName,
  settingsName,
}: {
  tableName: string;
  settingsName:
    | "columnVisibility"
    | "columnOrder"
    | "displayFlags"
    | "order_by"
    | "order_direction";
}) => {
  const allTableSettings = localStorage.getItem(localStorageKeys.TABLE_SETTING);
  if (allTableSettings) {
    const parsedTableSettings = JSON.parse(allTableSettings);
    const tableSettings = parsedTableSettings[tableName];
    if (tableSettings && tableSettings[settingsName]) {
      return tableSettings[settingsName];
    }
  }
};

export const setSettingsInStorage = ({
  tableName,
  settings,
}: {
  tableName: string;
  settings:
    | {
        name: "columnVisibility";
        settings: VisibilityState;
      }
    | {
        name: "columnOrder";
        settings: ColumnOrderState;
      }
    | {
        name: "displayFlags";
        settings: Record<string, string>;
      }
    | {
        name: "order_by";
        settings: string;
      }
    | {
        name: "order_direction";
        settings: "asc" | "desc";
      };
}) => {
  let allTableSettings: TableSettings = {};
  const allTableSettingsString = localStorage.getItem(
    localStorageKeys.TABLE_SETTING,
  );
  if (allTableSettingsString) {
    allTableSettings = JSON.parse(allTableSettingsString);
  }
  allTableSettings[tableName] = {
    ...allTableSettings[tableName],
    [settings.name]: settings.settings,
  };
  localStorage.setItem(
    localStorageKeys.TABLE_SETTING,
    JSON.stringify(allTableSettings),
  );
};
