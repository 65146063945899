import { RiskBadge, RiskBadgeProps } from "@/components/RiskBadge";
import { TooltipHelper } from "@/components/ui";
import { getPath } from "@/lib/utils";

import { RenderCellProps } from "./cell-types";

export const RiskBadgeCellComponent = <T extends object>({
  row,
  cellInfo,
}: RenderCellProps<T>) => {
  if (cellInfo.type !== "RISK_BADGE") {
    return null;
  }
  const score = getPath(row.original, cellInfo.metadata.scoreAccessor) as
    | number
    | null;
  const risk = getPath(row.original, cellInfo.metadata.riskAccessor) as string;
  const color = getPath(
    row.original,
    cellInfo.metadata.colorAccessor,
  ) as RiskBadgeProps["color"];
  const mode = getPath(
    row.original,
    cellInfo.metadata.modeAccessor,
  ) as RiskBadgeProps["mode"];
  const icon = getPath(row.original, cellInfo.metadata.iconAccessor) as string;
  const riskTooltip = getPath(
    row.original,
    cellInfo.metadata.riskTooltipAccessor,
  );

  if (score === null || score === undefined || !risk || !color) {
    if (score !== -1) {
      return "";
    }
  }

  return (
    <div className="inline-flex items-center gap-1">
      <RiskBadge
        color={color}
        label={risk}
        score={score}
        size={"sm"}
        mode={mode}
        icon={icon}
      />
      {riskTooltip && riskTooltip.length > 0 && (
        <TooltipHelper
          tooltipContent={riskTooltip}
          external={false}
          side="top"
        />
      )}
    </div>
  );
};
