import { cn } from "@/lib/utils";

const LoadingSpinner = (props: { text?: string; size?: string }) => {
  return (
    <div className="flex h-full flex-1 items-center justify-center">
      <div className="flex flex-col items-center justify-center text-center">
        <div
          className={cn(
            "size-24 animate-spin rounded-full border-b-2 border-gray-900",
            props.size,
          )}
        ></div>
        {props.text && <p className="mt-4 text-lg">{props.text}</p>}
      </div>
    </div>
  );
};

export { LoadingSpinner };
