import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import * as React from "react";

import { Icon } from "@/components/Icon";
import { cn } from "@/lib/utils";

const TooltipProvider = ({
  delayDuration = 0,
  ...props
}: TooltipPrimitive.TooltipProviderProps) => (
  <TooltipPrimitive.Provider delayDuration={delayDuration} {...props} />
);

const Tooltip = TooltipPrimitive.Root;

const TooltipTrigger = TooltipPrimitive.Trigger;

type TooltipContentProps = {
  external?: boolean;
};

// TODO: Use figma token for tooltip background color
const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> &
    TooltipContentProps
>(
  (
    { className, sideOffset = 4, children, external = false, ...props },
    ref,
  ) => (
    <TooltipPrimitive.Content
      ref={ref}
      sideOffset={sideOffset}
      className={cn(
        "z-50 inline-flex max-w-72 items-center gap-2 overflow-hidden text-balance rounded bg-[#14181F] px-2 py-1.5 text-center text-sm text-white animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
        className,
      )}
      {...props}
    >
      {external ? (
        <>
          {children}
          <Icon icon="fa-arrow-up-right" />
        </>
      ) : (
        children
      )}
    </TooltipPrimitive.Content>
  ),
);
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

const TooltipHelper = (props: {
  tooltipContent: string;
  external?: boolean;
  side?: React.ComponentPropsWithoutRef<
    typeof TooltipPrimitive.Content
  >["side"];
}) => {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <div className="flex h-6 w-6 shrink-0 items-center justify-center text-body-text-x-subtle hover:text-body-text-default">
          <i className="fa-exclamation-circle fa-light shrink-0 p-0.5" />
        </div>
      </TooltipTrigger>
      <TooltipContent side={props.side} external={props.external}>
        {props.tooltipContent}
      </TooltipContent>
    </Tooltip>
  );
};

export {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  TooltipProvider,
  TooltipHelper,
};
