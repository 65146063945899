import { router } from "@inertiajs/react";
import { addMinutes, differenceInMilliseconds } from "date-fns";
import React, { useEffect, useState } from "react";

import { axios } from "@/axios";
import { PageData } from "@/hooks/usePageData";
import { localStorageKeys } from "@/lib/constants";

const useSessionExpiresAt = (
  initialSessionExpiresAt: string,
  sessionLifetimeInMin: number,
) => {
  const [sessionExpiresAt, setSessionExpiresAt] = useState<string>();

  useEffect(() => {
    setSessionExpiresAt(initialSessionExpiresAt);
    const updateSession = () => {
      setSessionExpiresAt(
        addMinutes(new Date(), sessionLifetimeInMin).toISOString(),
      );
    };

    // update session expiry on every api request
    const interceptor = axios.interceptors.response.use(
      function (response) {
        updateSession();
        return response;
      },
      function (error) {
        updateSession();
        return Promise.reject(error);
      },
    );

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [initialSessionExpiresAt, sessionLifetimeInMin]);

  return { sessionExpiresAt, setSessionExpiresAt };
};

const useTimeout = (
  sessionExpiresAt: string | undefined,
  setLockScreen: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  useEffect(() => {
    if (!sessionExpiresAt) {
      return;
    }

    localStorage.setItem(localStorageKeys.SESSION_EXPIRES_AT, sessionExpiresAt);

    const timeoutInMs = differenceInMilliseconds(
      new Date(sessionExpiresAt),
      new Date(),
    );

    // lock screen when session expires
    const timeoutId = setTimeout(() => {
      setLockScreen(true);
    }, timeoutInMs);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [sessionExpiresAt, setLockScreen]);
};

const useListener = (
  lockScreen: boolean,
  setLockScreen: React.Dispatch<React.SetStateAction<boolean>>,
  sessionExpiresAt: string | undefined,
  setSessionExpiresAt: React.Dispatch<React.SetStateAction<string | undefined>>,
) => {
  useEffect(() => {
    const listener = (event: StorageEvent) => {
      // update this tab's session expiry when update on other tabs
      if (
        event.key === localStorageKeys.SESSION_EXPIRES_AT &&
        event.newValue &&
        sessionExpiresAt &&
        differenceInMilliseconds(
          new Date(event.newValue),
          new Date(sessionExpiresAt),
        ) > 0
      ) {
        setSessionExpiresAt(event.newValue);
        return;
      }

      // update this tab's screen lock when unlock or logout is triggered on other tabs
      const isUnlocked =
        event.key === localStorageKeys.LOCK_SCREEN_UNLOCKED &&
        event.newValue === "true";
      const isLoggedOut =
        event.key === localStorageKeys.LOCK_SCREEN_LOGOUT &&
        event.newValue === "true";

      if ((isUnlocked || isLoggedOut) && lockScreen) {
        setLockScreen(false);
        router.reload();
      }
    };

    window.addEventListener("storage", listener);

    return () => {
      window.removeEventListener("storage", listener);
    };
  }, [lockScreen, setLockScreen, sessionExpiresAt, setSessionExpiresAt]);
};

export const useLockScreen = (auth: PageData["auth"]) => {
  const [lockScreen, setLockScreen] = React.useState<boolean>(false);

  const { sessionExpiresAt, setSessionExpiresAt } = useSessionExpiresAt(
    auth.session_expires_at,
    auth.session_lifetime,
  );
  useTimeout(sessionExpiresAt, setLockScreen);
  useListener(lockScreen, setLockScreen, sessionExpiresAt, setSessionExpiresAt);

  // notify other tabs of lock screen unlock via localstorage
  const notifyLockScreenUnlocked = () => {
    localStorage.setItem(localStorageKeys.LOCK_SCREEN_UNLOCKED, "true");
    localStorage.removeItem(localStorageKeys.LOCK_SCREEN_UNLOCKED);
  };

  // notify other tabs of lock screen logout via localstorage
  const notifyLockScreenLogout = () => {
    localStorage.setItem(localStorageKeys.LOCK_SCREEN_LOGOUT, "true");
    localStorage.removeItem(localStorageKeys.LOCK_SCREEN_LOGOUT);
  };

  return {
    lockScreen,
    setLockScreen,
    notifyLockScreenUnlocked,
    notifyLockScreenLogout,
  };
};
