import {
  type LightboxImage,
  useLightboxStore,
} from "@/stores/use-lightbox-store";

export function useLightbox() {
  const { open } = useLightboxStore();

  return {
    openLightbox: (images: LightboxImage[], startIndex = 0) => {
      open(images, startIndex);
    },
  };
}
