import { AxiosError } from "axios";
import { type ClassValue, clsx } from "clsx";
import { formatDistanceToNow } from "date-fns";
import { format } from "date-fns-tz";
import getPath from "lodash.get";
import { FieldValues, Path, UseFormReturn } from "react-hook-form";
import { twMerge } from "tailwind-merge";

import {
  AsyncSelectOption,
  DynamicFormFieldSet,
} from "@/components/DynamicForm";
import { SelectedFilter } from "@/components/data-table/filters";
import { DocumentLink, Sample, TODO } from "@/types";

import { defaultErrorMessage } from "./constants";

export const timeFormats = ({
  date,
  formatString,
}: {
  date: string | null;
  formatString: string;
}) => {
  if (!date || date === "0000-00-00 00:00:00") {
    return ["-", "-", "-"];
  }
  // The last index is the local format without the timezone identifier
  return [
    formatDistanceToNow(new Date(date), { addSuffix: true }),
    format(new Date(date), `${formatString} z`).replace("GMT", "UTC"),
    format(new Date(date), `${formatString} z`)
      .replace("GMT", "UTC")
      .split(" UTC")[0],
  ];
};

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const getQueryParams = () => {
  const params = Object.fromEntries(
    new URLSearchParams(location.search).entries(),
  );
  return params;
};

export const showAgGrid = window.location.search.includes("ag-grid");

export const dateFormat = "dd MMM yyyy";
export const dateTimeFormat = "H:mm dd MMM yyyy";
export const timeFormat = "h:mmaaa";
export const timeZoneFormat = "h:mmaaa OO";

export type ValidDateFormats =
  | typeof dateFormat
  | typeof dateTimeFormat
  | typeof timeFormat
  | typeof timeZoneFormat;

export function hexToRGBArray(hex?: string): [number, number, number] {
  hex = (hex ? hex : "dddddd").toLowerCase();
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result
    ? [
        parseInt(result[1]!, 16),
        parseInt(result[2]!, 16),
        parseInt(result[3]!, 16),
      ]
    : [175, 175, 175];
}

export function hexToRGBAArray(
  hex?: string,
  alpha = 255,
): [number, number, number, number] {
  const rgb = hexToRGBArray(hex);
  return [...rgb, alpha];
}

export const transformValuesToDynamicFormMarkup = (
  markup: DynamicFormFieldSet[],
  data: TODO,
) => {
  //  This function maps through the markup and returns an object with the field names
  //  as keys and the values from the api response as values. It also converts the values
  //  to the correct type based on the field type.
  try {
    return markup.reduce((acc, fieldSet) => {
      const fieldData = fieldSet.fields.reduce(
        (fieldAcc: { [key: string]: unknown }, field) => {
          const value = getPath(data, field.accessorKey);

          switch (field.type.input) {
            case "ASYNC_SELECT": {
              const asyncFieldType = field.type as AsyncSelectOption;
              //  If value is blank, set field to empty array or empty string
              //  e.g. no asset has been set for an already existing audit
              if (!value) {
                fieldAcc[field.name] = asyncFieldType.isMulti ? [] : "";
                break;
              }
              //  when isMulti is true, value is an array of objects
              if (asyncFieldType.isMulti) {
                fieldAcc[field.name] = value.map(
                  (option: Record<string, string>) => ({
                    value: getPath(option, asyncFieldType.valueField),
                    label: getPath(option, asyncFieldType.labelField),
                  }),
                );
                break;
              }
              //  finally, when isMulti is false, value is a single object
              fieldAcc[field.name] = {
                value: getPath(value, asyncFieldType.valueField),
                label: getPath(value, asyncFieldType.labelField),
              };
              break;
            }
            case "SELECT": {
              //  Find the option in the field type options array that matches the value. Cast to string to avoid type errors
              //  as the API may return a number or string for the value of an object
              fieldAcc[field.name] =
                field.type.options.find(
                  (option) =>
                    option.value ===
                    String(
                      value?.value || value?.value === 0 ? value.value : value,
                    ),
                )?.value || "";
              break;
            }
            case "SWITCH": {
              fieldAcc[field.name] =
                typeof value === "boolean" ? value : !!value;
              break;
            }
            case "DATE":
              //  Date fields are returned as strings from the API in UTC format e.g. 2021-01-01T00:00:00.000000Z
              fieldAcc[field.name] = isNaN(Date.parse(value))
                ? null
                : new Date(value);
              break;
            default:
              //  All other field types are returned as strings from the API
              fieldAcc[field.name] = value || "";
              break;
          }
          return fieldAcc;
        },
        {},
      );
      // Merge fieldData into the accumulator which allows for multiple field sets
      return { ...acc, ...fieldData };
    }, {});
  } catch (error) {
    console.error("Error in parseDynamicFormMarkup:", error);
  }
};

export const prepareFormForApi = (
  formInfo: DynamicFormFieldSet[],
  values: Record<string, unknown>,
) => {
  const newValues = { ...values };
  for (let i = 0; i < formInfo.length; i += 1) {
    const fieldSet = formInfo[i];
    for (let j = 0; j < fieldSet.fields.length; j += 1) {
      const field = fieldSet.fields[j];
      if (field.type.input === "ASYNC_SELECT") {
        const fieldOptions = field.type as AsyncSelectOption;
        if (fieldOptions?.isMulti === true) {
          const asyncSelectValues = values[field.name] as {
            value: string;
          }[];
          newValues[field.name] = asyncSelectValues.map((a) => ({
            id: a.value,
          }));
        } else {
          newValues[field.name] = getPath(
            values[field.name],
            fieldOptions.valueField,
          );
        }
      }
    }
  }
  return newValues;
};

const onlyAssetDocumentLinks = (links: DocumentLink[]) => {
  return links.filter(
    (item) => item.link_type !== "App\\Models\\AsbestosSample",
  );
};

const formatNumber = (
  number: number | null | undefined,
  minFraction = 0,
  maxFraction = 0,
) => {
  if (number == null) {
    return 0;
  }

  return number.toLocaleString(undefined, {
    minimumFractionDigits: minFraction,
    maximumFractionDigits: maxFraction,
  });
};

export const handleFormErrors = <
  TFieldValues extends FieldValues = FieldValues,
  TContext = TODO,
  TTransformedValues extends FieldValues = TFieldValues,
>(
  form: UseFormReturn<TFieldValues, TContext, TTransformedValues>,
  error: AxiosError<{
    errors?: Record<Path<TFieldValues>, string>;
    message: string;
  }>,
  fallbackMessage: string = defaultErrorMessage,
) => {
  if (!error.response) {
    form.setError("root", { message: fallbackMessage });
    return;
  }

  const { errors, message } = error.response.data;
  if (errors) {
    const keys = Object.keys(errors) as Path<TFieldValues>[];
    keys.map((key) => {
      form.setError(key, {
        type: "manual",
        message: errors[key],
      });
    });
  } else {
    form.setError("root", { message });
  }
};

export const parseFilters = (filterKey?: string): SelectedFilter[] => {
  const searchParams = new URLSearchParams(window.location.search);
  const encodedFilters = searchParams.get("filters");
  const stringifiedJsonFilters = encodedFilters ? atob(encodedFilters) : "[]";
  const filters = JSON.parse(stringifiedJsonFilters) as SelectedFilter[];

  if (filterKey) {
    return filters.filter((item) => item.key === filterKey);
  }

  return filters;
};

export const downloadUrl = (url: string) => {
  const link = document.createElement("a");
  link.download = "name";
  link.href = url;
  link.click();
  link.remove();
};

export const getNextBarCode = (
  samples: Sample[],
  assessment: "Sampled" | "Visual assessment",
) => {
  const regex = assessment === "Sampled" ? /^S-\d+-.*$/ : /^VA-\d+-.*$/;
  const parentSamples = samples.filter((s) => !s.parent_id);
  const samplesWithOrderedBarcode = parentSamples.filter((s) =>
    s.barcode?.match(regex),
  );
  const sorted = samplesWithOrderedBarcode.sort((a, b) => {
    return Number(b.barcode.split("-")[1]) - Number(a.barcode.split("-")[1]);
  });
  const highestBarcode = sorted[0];
  const nextBarcodeNumber = highestBarcode
    ? String(Number(highestBarcode.barcode.split("-")[1]) + 1).padStart(2, "0")
    : "01";

  return assessment === "Sampled"
    ? `S-${nextBarcodeNumber}-B`
    : `VA-${nextBarcodeNumber}-P`;
};

export { getPath, onlyAssetDocumentLinks, formatNumber };
