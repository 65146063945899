"use no memo";

// Provider wrapper
import { useRef } from "react";

import { PaginatedResource } from "@/types/common-types";

import {
  TableConfigContext,
  TableConfigStore,
  createTableConfigStore,
} from "./table-config-store";

type TableProviderProps<TData> = React.PropsWithChildren<{
  tableData: PaginatedResource<TData>;
  updateParams?: (params: unknown) => void;
}>;

export const TableProvider = <TData,>({
  children,
  tableData,
  updateParams,
}: TableProviderProps<TData>) => {
  const storeRef = useRef<TableConfigStore<TData>>(null);

  // eslint-disable-next-line react-compiler/react-compiler
  if (!storeRef.current) {
    // eslint-disable-next-line react-compiler/react-compiler
    storeRef.current = createTableConfigStore(tableData, updateParams);
  }

  return (
    // eslint-disable-next-line react-compiler/react-compiler
    <TableConfigContext.Provider value={storeRef.current}>
      {children}
    </TableConfigContext.Provider>
  );
};
