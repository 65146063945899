import { SafetyPageAssetField } from "@/components/SafetyPageAssetField";
import { getPath } from "@/lib/utils";
import { AssetField, AssetOverviewCustomQuestions } from "@/types";

import { RenderCellProps } from "./cell-types";

export const AssetFieldBadgeCellComponent = <T extends object>({
  row,
  cellInfo,
}: RenderCellProps<T>) => {
  if (cellInfo.type !== "ASSET_FIELD_BADGE") {
    return null;
  }
  const field = getPath(row.original, cellInfo.fieldAccessor) as AssetField;
  const response = getPath(
    row.original,
    cellInfo.responseAccessor,
  ) as AssetOverviewCustomQuestions;

  return <SafetyPageAssetField field={field} response={response} />;
};
