import { useState } from "react";
import { toast } from "sonner";

import { Audit, Sample, SampleRow } from "@/types";

import { SampleForm } from "./SampleForm";
import {
  Button,
  Separator,
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "./ui";

export const AsbestosSampleCell = (props: {
  barcode: Sample["barcode"];
  sampleId: Sample["sample_id"];
  auditId: Audit["audit_id"];
  onDone: () => void;
}) => {
  const [open, setOpen] = useState(false);

  const isWebRegisterRoute = route().current()?.includes("web-register");
  if (isWebRegisterRoute) {
    return <span>{props.barcode}</span>;
  }

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <Button variant={"secondary"} onClick={() => setOpen(true)}>
          {props.barcode}
        </Button>
      </SheetTrigger>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>Edit sample</SheetTitle>
        </SheetHeader>
        <Separator className="my-4" />
        <SampleForm
          auditId={props.auditId}
          sample={{ as_sampleids: String(props.sampleId) } as SampleRow}
          onDone={() => {
            props.onDone();
            toast("Sample updated successfully");
            setOpen(false);
          }}
        />
      </SheetContent>
    </Sheet>
  );
};
