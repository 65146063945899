import { Icon } from "@/components/Icon";
import { getPath } from "@/lib/utils";

import { RenderCellProps } from "./cell-types";

export const LinkCountCellComponent = <T extends object>({
  row,
  accessorKey,
  cellInfo,
}: RenderCellProps<T>) => {
  const value = getPath(row.original, accessorKey);
  if (cellInfo.type !== "LINK_COUNT") {
    return null;
  }
  if (!value) {
    return "";
  }

  const count = getPath(row.original, cellInfo.metadata.countKey) as number;

  return (
    <span>
      {value}{" "}
      {count > 0 && (
        <span className={"text-blue-500"}>
          <Icon icon="fa-link" iconStyle="LIGHT" />
          {count}
        </span>
      )}
    </span>
  );
};
