import { Link } from "@inertiajs/react";
import { ALIGN_OPTIONS } from "@radix-ui/react-popper";

import { cn } from "@/lib/utils";
import { Audit, AuditRow } from "@/types";

import { HoverCard, HoverCardContent, HoverCardTrigger } from "./ui/hover-card";

export const AuditAssetMenu = (props: {
  audit: AuditRow | Audit;
  href?: string;
  align?: (typeof ALIGN_OPTIONS)[number];
  side?: "bottom" | "top" | "right" | "left" | undefined;
  sideOffset?: number;
  className?: string;
}) => {
  const firstAsset = Object.values(props.audit.assets)[0];
  const additionalAssets =
    Object.keys(props.audit.assets).length > 1
      ? ` +${Object.keys(props.audit.assets).length - 1}`
      : "";

  const firstAssetLabel = firstAsset?.label || "-";

  return (
    <>
      <HoverCard openDelay={100} closeDelay={300}>
        <HoverCardTrigger asChild>
          <Link
            href={
              props.href ||
              (firstAsset &&
                route("inertia.asset.show", {
                  asset: firstAsset?.route_key,
                  tab: "overview",
                }))
            }
          >
            <div
              className={cn(
                "flex max-w-72 items-center gap-x-3 text-wrap rounded-sm px-1.5 hover:bg-[#14181F1A]",
                props.className,
              )}
            >
              <span className="truncate">{firstAssetLabel}</span>
              {additionalAssets && (
                <span className="shrink-0 text-body-text-x-subtle">
                  {additionalAssets}
                </span>
              )}
            </div>
          </Link>
        </HoverCardTrigger>
        {Object.keys(props.audit.assets).length > 1 && (
          <HoverCardContent
            align={props.align || "start"}
            side={props.side || "right"}
            sideOffset={props.sideOffset || 4}
            className="flex max-h-96 w-full flex-col gap-y-0.5 overflow-y-auto rounded p-1"
          >
            {Object.keys(props.audit.assets).map((uuid) => (
              <AssetAuditLink
                key={props.audit.assets[uuid].uuid}
                href={route("inertia.asset.show", {
                  asset: props.audit.assets[uuid].route_key,
                  tab: "overview",
                })}
                label={props.audit.assets[uuid].label}
              />
            ))}
          </HoverCardContent>
        )}
      </HoverCard>
    </>
  );
};

const AssetAuditLink = (props: { href: string; label: string }) => {
  return (
    <div className="flex">
      <Link
        href={props.href}
        className="flex h-full w-full rounded hover:bg-accent"
      >
        <span className="px-2 py-1.5 text-sm text-body-text-subtle hover:text-body-text-default">
          {props.label}
        </span>
      </Link>
    </div>
  );
};
