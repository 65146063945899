import { type VariantProps, cva } from "class-variance-authority";
import * as React from "react";

import { cn } from "@/lib/utils";

import { Icon } from "../Icon";

const badgeVariants = cva(
  "inline-flex items-center gap-x-1 px-2 py-1 rounded-full border text-sm",
  {
    variants: {
      color: {
        blue: "border-primary text-primary-foreground",
        black: "border-secondary text-secondary-foreground",
        transparent: "text-foreground border-secondary-foreground",
        green: "text-[#067647] border-[#4DB24D]",
        orange: "text-[#E5801A] border-[#E5801A]",
        red: "text-[#E51A1A] border-[#EB4747]",
      },
      variant: {
        contained: "bg-current",
        outlined: "bg-transparent border-transparent",
      },
    },
    compoundVariants: [
      { color: "blue", variant: "contained", className: "bg-primary" },
      { color: "black", variant: "contained", className: "bg-secondary" },
      {
        color: "transparent",
        variant: "contained",
        className: "bg-transparent",
      },
      { color: "green", variant: "contained", className: "bg-[#EDFDF8]" },
      { color: "orange", variant: "contained", className: "bg-[#FDF5ED]" },
      { color: "red", variant: "contained", className: "bg-[#FDEDED]" },
    ],
    defaultVariants: {
      color: "green",
      variant: "contained",
    },
  },
);

const dotColors = {
  blue: "text-primary",
  black: "text-secondary",
  transparent: "text-foreground",
  green: "text-[#17B26A]",
  orange: "text-[#E5801A]",
  red: "text-[#EB4747]",
};

const iconColors = {
  blue: "text-primary",
  black: "text-secondary",
  transparent: "text-foreground",
  green: "text-[#108C10]",
  orange: "text-[#E5801A]",
  red: "text-[#E51A1A]",
};

export type BadgeProps = React.HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof badgeVariants> & {
    label?: string;
    showDot?: boolean;
    icon?: string;
    variant?: "contained" | "outlined";
    color?: "blue" | "black" | "transparent" | "green" | "orange" | "red";
  } & ({ children?: never } | { children: React.ReactNode });

const Badge = React.forwardRef<HTMLDivElement, BadgeProps>(
  (
    {
      className,
      color = "blue",
      variant = "contained",
      label,
      showDot,
      icon,
      children,
      ...props
    },
    ref,
  ) => {
    return (
      <div
        ref={ref}
        className={cn(badgeVariants({ color, variant }), className)}
        {...props}
      >
        {showDot && (
          <span
            className={cn("h-2 w-2 rounded-full bg-current", dotColors[color])}
          />
        )}

        {icon && (
          <Icon
            icon={icon}
            iconStyle="REGULAR"
            className={cn(iconColors[color])}
          />
        )}

        {label && <span className="font-medium">{label}</span>}

        {!label && !showDot && !icon && children}
      </div>
    );
  },
);

Badge.displayName = "Badge";

export { Badge, badgeVariants };
