import { create } from "zustand";

export type LightboxImage =
  | {
      url: string;
      alt?: string;
      width?: number;
      height?: number;
    }
  | string;

interface LightboxState {
  isOpen: boolean;
  images: LightboxImage[];
  currentIndex: number;
  open: (images: LightboxImage[], startIndex?: number) => void;
  close: () => void;
  next: () => void;
  prev: () => void;
  setIndex: (index: number) => void;
}

export const useLightboxStore = create<LightboxState>((set) => ({
  isOpen: false,
  images: [],
  currentIndex: 0,
  open: (images, startIndex = 0) =>
    set({
      isOpen: true,
      images,
      currentIndex: startIndex,
    }),
  close: () => set({ isOpen: false }),
  next: () =>
    set((state) => ({
      currentIndex: (state.currentIndex + 1) % state.images.length,
    })),
  prev: () =>
    set((state) => ({
      currentIndex:
        (state.currentIndex - 1 + state.images.length) % state.images.length,
    })),
  setIndex: (index) => set({ currentIndex: index }),
}));
