import { create } from "zustand";
import { persist } from "zustand/middleware";

import { localStorageKeys } from "@/lib/constants";

type TimeCardStoreProps = {
  activeFormatIndex: number;
  setActiveFormatIndex: (
    index: number | ((prevIndex: number) => number),
  ) => void;
};

export const useTimeCardStore = create<TimeCardStoreProps>()(
  persist(
    (set, get) => ({
      activeFormatIndex: 1,
      setActiveFormatIndex: (index) => {
        if (typeof index === "function") {
          // Pass the current state to the function and use the result
          set({ activeFormatIndex: index(get().activeFormatIndex) });
        } else {
          // Directly set the value if it's not a function
          set({ activeFormatIndex: index });
        }
      },
    }),
    {
      name: localStorageKeys.TIME_CARD_ACTIVE_INDEX,
    },
  ),
);
