//#region imports
import { Link, router } from "@inertiajs/react";
import { useMutation } from "@tanstack/react-query";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { Fragment, useState } from "react";
import Realistic from "react-canvas-confetti/dist/presets/realistic";
import { TConductorInstance } from "react-canvas-confetti/dist/types";
import { useForm, useWatch } from "react-hook-form";
import { toast } from "sonner";

import { axios, controller } from "@/axios";
import { Icon } from "@/components/Icon";
import { OctfolioLogo } from "@/components/OctfolioLogo";
import { UserAvatar } from "@/components/UserAvatar";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Badge,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  ScrollArea,
  Textarea,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui";
import { NavItem, PageData, usePageData } from "@/hooks/usePageData";
import { cn } from "@/lib/utils";
import { useAppContext } from "@/providers/AppProvider";
import { TODO } from "@/types";

import { MemberSheet } from "./MemberSheet";
import { Button, ButtonIconLeft } from "./ui/button";

//#endregion

export const AsideMenu = () => {
  const { mobileSidebarOpen, desktopSidebarOpen } = useAppContext();
  const { navItems } = usePageData();
  return (
    <nav
      className={`dark fixed bottom-0 left-0 top-0 z-50 flex h-full w-[250px] flex-col bg-blue-950 text-body-text-subtle transition-transform duration-300 ease-in-out ${
        desktopSidebarOpen ? "lg:left-[250px]" : "lg:left-[64px] lg:w-[64px]"
      } ${mobileSidebarOpen ? "translate-x-0" : "-translate-x-full"}`}
      aria-label="Main Sidebar Navigation"
    >
      <AsideHeader menuItems={navItems.settingsDropdown} />
      <div className="flex-1 overflow-y-auto pb-2">
        <Navigation menuItems={navItems.mainNavigation} />
      </div>
      <div className="mb-4">
        <LegacySwitcher />
        <div className="relative z-50 px-4">
          <HelpIcon />
        </div>
        <OctfolioLogo
          className={`absolute bottom-2 h-[10px] w-full opacity-50 ${
            !desktopSidebarOpen ? "hidden" : ""
          }`}
          fill="white"
        />
      </div>
    </nav>
  );
};

type VersionSwitcherFeedbackData = {
  type: "switcher_feedback";
  message: string;
};

const VersionSwitcherDialog = (props: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onDone: () => void;
  fireConfetti: () => void;
}) => {
  const { open, onOpenChange, onDone, fireConfetti } = props;
  const pageData = usePageData();

  const form = useForm<VersionSwitcherFeedbackData>({
    defaultValues: { message: "", type: "switcher_feedback" },
    mode: "onChange",
  });

  const mutation = useMutation({
    mutationFn: (values: VersionSwitcherFeedbackData) => {
      return axios.post<{ success: boolean }>(route("api.feedback"), {
        ...values,
        username: pageData.auth.user.full_name,
        email: pageData.auth.user.email,
        page: window.location.href || "",
      });
    },
    onSuccess: () => {
      onDone();
      form.reset();
      fireConfetti();
      toast.success("Thanks!", {
        description:
          "We appreciate you providing feedback and will use it to improve our product.",
        duration: 5000,
      });
      router.post(route("switch-versions"));
    },
    onError: () => {
      toast.error("Error while sending feedback");
      router.post(route("switch-versions"));
    },
  });

  const skipFeedback = () => {
    onOpenChange(false);
    toast.success("Switching to Classic");
  };

  const handleSubmit = form.handleSubmit((values) => {
    if (!values.message.trim()) {
      form.setError("message", {
        message: "Please provide feedback or click 'Skip feedback' below",
      });
      return;
    }
    mutation.mutate(values);
  });

  const handleOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      form.reset();
      onOpenChange(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={handleOpenChange}>
      <DialogContent className="flex w-[45%] flex-col gap-0">
        <div className="pb-4">
          <DialogTitle className="text-lg !font-normal text-body-text-default">
            Feedback
          </DialogTitle>
          <DialogDescription className="mt-1 text-base text-body-text-subtle">
            This helps us prioritise what to build or improve
          </DialogDescription>
        </div>
        <Form {...form}>
          <form onSubmit={handleSubmit}>
            <div className="-mx-6 border-b border-t border-body-border-default bg-body-surface-light px-16 py-12">
              <FormField
                control={form.control}
                name="message"
                render={({ field }) => (
                  <FormItem className="grid gap-4 py-4">
                    <FormLabel className="-mb-4 text-sm font-semibold text-body-text-default">
                      {`I'm switching back to Classic because...`}
                    </FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        placeholder={`e.g "it's harder to apply results"`}
                      />
                    </FormControl>
                    <FormMessage className="!-mt-4 !text-sm !font-normal !text-body-text-danger-default">
                      {form.formState.errors.message?.message}
                    </FormMessage>
                  </FormItem>
                )}
              />
            </div>
            <div className="flex flex-row items-center justify-end">
              <div className="-mx-6 -mb-6 flex flex-row gap-x-4 p-4">
                <Link
                  href={route("switch-versions")}
                  method="post"
                  as="button"
                  className="text-sm text-body-text-default hover:text-body-text-subtle"
                  onClick={skipFeedback}
                >
                  Skip feedback
                </Link>
                <ButtonIconLeft icon="fa-send" type="submit">
                  Submit & switch to Classic
                </ButtonIconLeft>
              </div>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

const LegacySwitcher = () => {
  const { desktopSidebarOpen } = useAppContext();
  const [VersionSwitcherDialogOpen, setVersionSwitcherDialogOpen] =
    useState(false);
  const [conductor, setConductor] = useState<TConductorInstance>();

  const { auth } = usePageData();

  const onInit = ({ conductor }: { conductor: TConductorInstance }) => {
    setConductor(conductor);
  };

  console.info("desktopSidebarOpen", desktopSidebarOpen);
  const displayClassicBlurb = useFeatureFlagEnabled(
    "display_switch_to_classic_blurb",
  );
  const displayClassicButton = useFeatureFlagEnabled(
    "display_switch_to_classic_button",
  );

  return (
    <div className="w-full px-4 py-3">
      <div className="flex flex-col space-y-4">
        {desktopSidebarOpen && displayClassicBlurb && (
          <div className="space-y-2">
            <h2 className="text-xs text-[#f9fafb]/90">
              Octfolio has a new look!
            </h2>
            <p className="text-xs text-[#f9fafb]/40">
              You{"'"}re currently trialling the new Octfolio layout. You can
              switch between this new layout and the classic layout at anytime.
              For more info, see this{" "}
              <a
                href="https://changelog.octfolio.com/#111"
                target={"_blank"}
                className="underline"
              >
                release note.
              </a>
            </p>
          </div>
        )}
        {(auth.user.is_super_admin || displayClassicButton) && (
          <Button
            className="flex w-full flex-row items-center justify-center rounded border border-[#ffffff]/10 bg-[#1F242E] py-1.5 text-left hover:bg-[#1F242E]/70"
            onClick={() => setVersionSwitcherDialogOpen(true)}
          >
            <div className="flex grow items-center justify-center space-x-2 px-8 text-[#f9fafb]/90">
              <Icon icon="fa-shuffle" iconStyle="SOLID" />
              {desktopSidebarOpen && (
                <span className="h-5 grow text-sm">Switch to Classic</span>
              )}
            </div>
          </Button>
        )}
      </div>
      <Realistic
        onInit={onInit}
        className="pointer-events-none absolute inset-0 z-90 flex h-screen w-screen items-center justify-center"
      />
      <VersionSwitcherDialog
        open={VersionSwitcherDialogOpen}
        onOpenChange={setVersionSwitcherDialogOpen}
        onDone={() => {
          setVersionSwitcherDialogOpen(false);
        }}
        fireConfetti={() => conductor?.shoot()}
      />
    </div>
  );
};

type FeedbackFormData = {
  type: "problem" | "question" | "feedback";
  message: string;
};

const FeedbackDialog = (props: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onDone: () => void;
  fireConfetti: () => void;
}) => {
  const { open, onOpenChange, onDone, fireConfetti } = props;

  const pageData = usePageData();
  const form = useForm<FeedbackFormData>({
    values: { type: "problem", message: "" },
  });
  const message = useWatch({ name: "message", control: form.control });
  const mutation = useMutation({
    mutationFn: (
      data: FeedbackFormData & {
        username: string;
        email: string;
        page: string;
      },
    ) => {
      return axios.post<{ success: boolean }>(route("api.feedback"), data);
    },
    onSuccess: () => {
      if (form.getValues().type === "feedback") {
        fireConfetti();
      }
      onDone();
      form.reset();
    },
    onError: () => {
      toast.error("Error while sending feedback");
    },
  });

  const onSubmit = (values: FeedbackFormData) => {
    const submitValues = {
      ...values,
      username: pageData.auth.user.full_name,
      email: pageData.auth.user.email,
      page: window.location.href || "",
    };
    console.log({ submitValues });
    mutation.mutate(submitValues);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="flex w-[45%] flex-col gap-0">
        <DialogTitle className="!font-normal text-body-text-default">
          Send feedback
        </DialogTitle>
        <DialogDescription className="mt-1 text-body-text-subtle">
          We{"'"}re working on a number of improvements to Octfolio{"'"}s layout
          & workflow. Feel free to provide as much or as little feedback (and as
          frequently) as you{"'"}d like.
        </DialogDescription>
        <hr className="-mx-6 my-4 h-px border-0 bg-table-body-default-border dark:bg-gray-700"></hr>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={form.control}
              name="type"
              render={({ field }) => {
                return (
                  <div className="flex space-x-1">
                    {[
                      { type: "problem", label: "Problem", icon: "fa-bug" },
                      {
                        type: "question",
                        label: "Question",
                        icon: "fa-question",
                      },
                      {
                        type: "feedback",
                        label: "Feedback",
                        icon: "fa-comment",
                      },
                    ].map((item) => {
                      const className =
                        (item.type === field.value && "bg-gray-200") || "";

                      return (
                        <ButtonIconLeft
                          key={item.type}
                          icon={item.icon}
                          variant={"tertiary"}
                          type="button"
                          className={className}
                          onClick={() => {
                            field.onChange(item.type);
                          }}
                        >
                          {item.label}
                        </ButtonIconLeft>
                      );
                    })}
                  </div>
                );
              }}
            />

            <FormField
              control={form.control}
              name="message"
              render={({ field }) => {
                return (
                  <FormItem className="grid gap-4 py-4">
                    <FormControl>
                      <Textarea {...field} placeholder="I think..." rows={5} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <div className="flex flex-row items-center justify-between">
              <div className="flex-1 text-xs text-body-text-x-subtle">
                You can also email{" "}
                <a href="mailto:support@octfolio.com">support@octfolio.com</a>
              </div>
              <div className="flex flex-row">
                <Button
                  variant={"secondary"}
                  className="mr-2"
                  type="button"
                  onClick={() => {
                    onOpenChange(false);
                  }}
                >
                  Cancel
                </Button>
                <ButtonIconLeft
                  icon="fa-send"
                  type="submit"
                  disabled={!message || mutation.isPending}
                >
                  Submit feedback
                </ButtonIconLeft>
              </div>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

const HelpIcon = () => {
  //  floating action help button bottom left screen with popup
  const [openFeedback, setOpenFeedback] = useState(false);
  const [conductor, setConductor] = useState<TConductorInstance>();

  const onInit = ({ conductor }: { conductor: TConductorInstance }) => {
    setConductor(conductor);
  };

  const onDone = () => {
    setOpenFeedback(false);
    toast.success("Thanks!", {
      description:
        "We appreciate you providing feedback and will use it to improve our product.",
      duration: 10000,
    });
  };

  return (
    <>
      <Realistic
        onInit={onInit}
        className="pointer-events-none absolute inset-0 z-90 flex h-screen w-screen items-center justify-center"
      />

      <FeedbackDialog
        open={openFeedback}
        onOpenChange={setOpenFeedback}
        onDone={onDone}
        fireConfetti={() => conductor?.shoot()}
      />

      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="tertiary"
            size="icon"
            className={cn(
              "flex items-center justify-center rounded-full bg-gray-900 text-white shadow-lg",
              "focus-visible:ring-none focus:outline-none focus-visible:rounded-full",
            )}
          >
            <Icon icon="fa-question" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          className="w-56 border-none bg-gray-900 text-gray-200"
          align="start"
        >
          <DropdownMenuLabel>Help</DropdownMenuLabel>
          <DropdownMenuItem className="bg-gray-900 !p-0 focus:rounded focus:bg-gray-950 focus:text-white">
            <a
              className="flex w-full flex-row items-center space-x-2 px-2 py-1.5 text-left"
              target="_blank"
              href={"https://help.octfol.io"}
              type="button"
            >
              <Icon icon="fa-book" />
              <span className="h-5 grow text-sm">Documentation</span>
            </a>
          </DropdownMenuItem>
          <DropdownMenuItem className="bg-gray-900 !p-0 focus:rounded focus:bg-gray-950 focus:text-white">
            <a
              className="flex w-full flex-row items-center space-x-2 px-2 py-1.5 text-left"
              target="_blank"
              href={"https://changelog.octfolio.com/"}
              type="button"
            >
              <Icon icon="fa-code-pull-request" />
              <span className="h-5 grow text-sm">Changelog</span>
            </a>
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            className="bg-gray-900 !p-0 focus:rounded focus:bg-gray-950 focus:text-white"
            onSelect={() => {
              Intercom("showNewMessage");
            }}
          >
            <div className="flex w-full flex-row items-center space-x-2 px-2 py-1.5 text-left">
              <Icon icon="fa-question" />
              <span className="h-5 grow text-sm">Contact support</span>
            </div>
          </DropdownMenuItem>
          <DropdownMenuItem
            className="bg-gray-900 !p-0 focus:rounded focus:bg-gray-950 focus:text-white"
            onSelect={() => setOpenFeedback(true)}
          >
            <div className="flex w-full flex-row items-center space-x-2 px-2 py-1.5 text-left">
              <Icon icon="fa-comment-dots" />
              <span className="h-5 grow text-sm">Send feedback</span>
            </div>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
};

const SettingsHeader = () => {
  return (
    <div className="flex h-[44px] justify-between space-x-2 px-2 py-1.5">
      <Link
        href={route("dashboard")}
        className="group flex w-full items-center space-x-2 rounded text-white hover:cursor-pointer hover:bg-blue-900"
      >
        <div className="flex h-8 w-8 items-center justify-center group-hover:text-white">
          <Icon icon="fa-chevron-left" iconStyle="LIGHT" />
        </div>
        <span className="group-hover:text-white">Settings</span>
      </Link>
    </div>
  );
};

const WorkspaceSwitcher = () => {
  const [open, setOpen] = useState(false);

  const { company, auth } = usePageData();

  const { desktopSidebarOpen } = useAppContext();

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <Tooltip>
        <DropdownMenuTrigger asChild>
          {desktopSidebarOpen ? (
            <Button
              variant="tertiary"
              className="h-8 flex-grow justify-start overflow-hidden text-ellipsis px-2 text-sm text-gray-200 hover:bg-blue-900 hover:text-white"
            >
              <span className="truncate">{company.companyName}</span>
            </Button>
          ) : (
            <TooltipTrigger asChild>
              <Button
                variant="tertiary"
                className="mt-2 flex h-8 w-8 items-center justify-center border text-base text-gray-200 hover:bg-blue-900 hover:text-white"
              >
                <Icon icon="fa-font-awesome" iconStyle="LIGHT" />
              </Button>
            </TooltipTrigger>
          )}
        </DropdownMenuTrigger>
        <TooltipContent side="right">{company.companyName}</TooltipContent>
      </Tooltip>
      <DropdownMenuContent
        className="rounded border-none bg-[#1F242E]"
        align="start"
        asChild
      >
        <ScrollArea
          className={`${auth.companies.length > 12 ? "h-[528px]" : ""}`}
        >
          <div>
            {auth.companies.map((c) => {
              const isCurrent = c.uuid === company.companyUuid;
              return (
                <DropdownMenuItem
                  key={c.uuid}
                  className="bg-[#1F242E] !p-0 focus:rounded focus:bg-[#F9FAFB]/10 focus:text-white"
                >
                  <Link
                    className={`w-full px-2 py-1.5 text-left text-sm ${
                      isCurrent ? "text-white" : "text-white/60"
                    } hover:text-white`}
                    href={route("change-workspace")}
                    onClick={() => {
                      setOpen(false);
                      toast.loading("Loading " + c.companyname, {
                        duration: Infinity,
                      });
                    }}
                    method="post"
                    as="button"
                    type="button"
                    data={{ companyUuid: c.uuid }}
                  >
                    <div className="flex items-center justify-between gap-x-4">
                      <div className="mr-4 flex-grow">
                        <span>{c.companyname}</span>
                      </div>
                      <div className="flex items-center gap-x-2">
                        <span>{c.shortname}</span>
                        <span>({c.domain})</span>
                        {auth.user.is_super_admin && (
                          <span className={"size-5"}>{c.version}.x</span>
                        )}
                        <div className="w-5 shrink-0">
                          {isCurrent && <Icon icon="fa-check" />}
                        </div>
                      </div>
                    </div>
                  </Link>
                </DropdownMenuItem>
              );
            })}
          </div>
        </ScrollArea>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const Divider = () => {
  const { desktopSidebarOpen } = useAppContext();
  return (
    <div
      className={cn(
        "flex h-8 items-center p-2",
        desktopSidebarOpen ? "mx-2" : "w-8 flex-row justify-center",
      )}
    >
      <div className="h-px w-full bg-gray-700 opacity-50" />
    </div>
  );
};

const AsideHeader = ({ menuItems }: { menuItems: NavItem[] }) => {
  const { desktopSidebarOpen } = useAppContext();
  const { platform } = usePageData();

  const [open, setOpen] = useState(false);
  const [isProfileSheetOpen, setIsProfileSheetOpen] = useState(false);

  const { auth } = usePageData();

  const renderSlideoutType = (menuItem: NavItem) => {
    switch (menuItem.component) {
      case "profile":
        return (
          <Fragment key={menuItem.id}>
            <DropdownMenuItem
              className="bg-gray-900 !p-0 focus:rounded focus:bg-gray-950 focus:text-white"
              onSelect={() => {
                setIsProfileSheetOpen(true);
              }}
            >
              <div className="flex w-full flex-row items-center space-x-2 px-2 py-1.5 text-left">
                {menuItem.icon && (
                  <Icon icon={menuItem.icon} iconStyle="SOLID" />
                )}
                <span className="h-5 grow text-sm">{menuItem.label}</span>
              </div>
            </DropdownMenuItem>
          </Fragment>
        );
      default:
        return null;
    }
  };

  if (route().current("settings.*")) {
    return <SettingsHeader />;
  }

  return (
    <div
      className={cn(
        "flex justify-between",
        desktopSidebarOpen
          ? "h-[44px] space-x-2 px-2 py-1.5"
          : "flex-col items-center",
      )}
    >
      <WorkspaceSwitcher />
      <div
        className={cn(
          "flex items-center justify-center",
          desktopSidebarOpen ? "flex-shrink-0" : "mt-2",
        )}
      >
        <DropdownMenu open={open} onOpenChange={setOpen}>
          <DropdownMenuTrigger asChild>
            <UserAvatar user={auth.user} />
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="w-56 border-none bg-gray-900 text-gray-200"
            align="start"
          >
            <DropdownMenuLabel>{auth.user.full_name}</DropdownMenuLabel>
            {menuItems.map((menuItem) => {
              switch (menuItem.type) {
                case "logout": {
                  return (
                    <Fragment key={menuItem.id}>
                      <DropdownMenuItem className="bg-gray-900 !p-0 focus:rounded focus:bg-gray-950 focus:text-white">
                        <Link
                          className="flex w-full flex-row items-center space-x-2 px-2 py-1.5 text-left"
                          href={route("logout")}
                          as="button"
                          method="post"
                          onClick={() => {
                            setOpen(false);
                            controller.abort();
                          }}
                        >
                          <div className="flex grow space-x-2">
                            <Icon icon="fa-person-to-door" iconStyle="SOLID" />
                            <span className="h-5 grow text-sm">Logout</span>
                          </div>
                          <span className="text-gray-600">
                            {platform.version}
                          </span>
                        </Link>
                      </DropdownMenuItem>
                    </Fragment>
                  );
                }
                case "slideout": {
                  return renderSlideoutType(menuItem);
                }
                default:
                  return (
                    <Fragment key={menuItem.id}>
                      <DropdownMenuItem className="bg-gray-900 !p-0 focus:rounded focus:bg-gray-950 focus:text-white">
                        <Link
                          className="flex w-full flex-row items-center space-x-2 px-2 py-1.5 text-left"
                          href={menuItem.link as string}
                          type="button"
                          onClick={() => setOpen(false)}
                        >
                          {menuItem.icon && (
                            <Icon icon={menuItem.icon} iconStyle="SOLID" />
                          )}
                          <span className="h-5 grow text-sm">
                            {menuItem.label}
                          </span>
                        </Link>
                      </DropdownMenuItem>
                    </Fragment>
                  );
              }
            })}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      {!desktopSidebarOpen && <Divider />}
      <MemberSheet
        memberRouteKey={auth.user.user_id as TODO}
        isProfileUpdate={true}
        open={isProfileSheetOpen}
        onOpenChange={setIsProfileSheetOpen}
        onDone={() => {
          setIsProfileSheetOpen(false);
          router.reload();
        }}
      />
    </div>
  );
};

type MenuItemLinkProps = {
  menuItem: NavItem;
  isActive?: boolean;
};

const MenuItemLink = ({ menuItem, isActive = false }: MenuItemLinkProps) => {
  const { desktopSidebarOpen } = useAppContext();

  if (!desktopSidebarOpen) {
    return (
      <Tooltip delayDuration={0}>
        <TooltipTrigger asChild>
          <div>
            <MenuItemContent menuItem={menuItem} isActive={isActive} />
          </div>
        </TooltipTrigger>
        <TooltipContent side="right">
          <>
            {menuItem.label}
            {menuItem.badge && <span>{menuItem.badge}</span>}
          </>
        </TooltipContent>
      </Tooltip>
    );
  }

  return <MenuItemContent menuItem={menuItem} isActive={isActive} />;
};

const MenuItemContent = ({ menuItem, isActive }: MenuItemLinkProps) => {
  const { desktopSidebarOpen } = useAppContext();

  if (menuItem.submenu.length) {
    return (
      <AccordionItem
        value={menuItem.label}
        key={menuItem.id}
        className="border-b-0"
      >
        <AccordionTrigger
          className={cn(
            "relative",
            "justify-start hover:no-underline",
            "h-8 w-full items-center rounded text-gray-200 hover:bg-blue-900 hover:text-white",
            desktopSidebarOpen
              ? "mx-2 space-x-2 px-1 py-1"
              : "w-8 justify-center",
          )}
          chevronClassName={cn(!desktopSidebarOpen ? "hidden" : "")}
        >
          {menuItem.icon && <Icon icon={menuItem.icon} iconStyle="SOLID" />}
          <span
            className={`h-5 text-left text-sm ${!desktopSidebarOpen ? "hidden" : ""}`}
          >
            {menuItem.label}
          </span>
          {desktopSidebarOpen && (
            <>
              {menuItem.badge && (
                <span className="absolute inset-y-0 right-1 flex items-center justify-center">
                  <Badge color={"black"}>{menuItem.badge}</Badge>
                </span>
              )}
            </>
          )}
        </AccordionTrigger>
        <AccordionContent className="py-1">
          <Navigation menuItems={menuItem.submenu} />
        </AccordionContent>
      </AccordionItem>
    );
  }

  const Comp = menuItem.new_window ? "a" : Link;
  return (
    <Comp
      href={menuItem.link as string}
      target={menuItem.new_window ? "_blank" : "_self"}
      className={cn(
        "flex h-8 items-center justify-center rounded text-gray-200 hover:bg-blue-900 hover:text-white",
        isActive ? "bg-blue-900 text-white" : "",
        desktopSidebarOpen ? "mx-2 space-x-2 px-1 py-1" : "w-8",
      )}
    >
      {menuItem.icon && <Icon icon={menuItem.icon} iconStyle="SOLID" />}
      <span
        className={`h-5 grow text-sm ${!desktopSidebarOpen ? "hidden" : ""}`}
      >
        {menuItem.label}
      </span>
      <span className={!desktopSidebarOpen ? "hidden" : ""}>
        {menuItem.new_window ? (
          <Icon icon="fa-regular fa-arrow-up-right" />
        ) : (
          <>
            {menuItem.badge && <Badge color={"black"}>{menuItem.badge}</Badge>}
          </>
        )}
      </span>
    </Comp>
  );
};

const Navigation = ({
  menuItems,
}: {
  menuItems: PageData["navItems"]["mainNavigation"];
}) => {
  const { desktopSidebarOpen } = useAppContext();

  const openParent = menuItems.find((item) => {
    if (item.submenu.length) {
      const childLinks = item.submenu.map((c) => c.link);
      const isActive = childLinks.includes(window.location.href.split("?")[0]);
      return isActive;
    }
  });

  return (
    <div
      className={cn(
        "w-full",
        !desktopSidebarOpen ? "flex flex-col items-center justify-center" : "",
      )}
    >
      <Accordion
        type="multiple"
        defaultValue={openParent ? [openParent.label] : []}
      >
        {menuItems.map((menuItem) => {
          const isActive = window.location.href.split("?")[0] === menuItem.link;
          const key = menuItem.id;
          switch (menuItem.type) {
            case "divider":
              return <Divider key={key} />;
            case "header":
              return (
                <Fragment key={key}>
                  <div className="flex h-[34px] select-none items-center p-2">
                    <span className="text-xs text-gray-600">
                      {menuItem.label}
                    </span>
                  </div>
                </Fragment>
              );
            default:
              return (
                <Fragment key={key}>
                  <MenuItemLink menuItem={menuItem} isActive={isActive} />
                </Fragment>
              );
          }
        })}
      </Accordion>
    </div>
  );
};
