import { memo, useMemo } from "react";

import { getPath } from "@/lib/utils";

import { RenderCellProps } from "./cell-types";

const HighlightedText = memo(
  ({ text }: { text: string | undefined }) => {
    const searchParams = new URLSearchParams(window.location.search);
    const search = searchParams.get("search");

    const [searchWords, regex] = useMemo(() => {
      const escapedSearch = search?.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
      const words = escapedSearch?.split(/\s+/).filter(Boolean);
      return [words, new RegExp(`(${words?.join("|")})`, "gi")];
    }, [search]);

    if (!searchWords || searchWords?.length === 0 || !search || !text) {
      return <>{text}</>;
    }

    // Handle single character search terms specially
    if (searchWords.length === 1 && searchWords[0].length === 1) {
      const stringText = String(text);
      const firstIndex = stringText
        .toLowerCase()
        .indexOf(searchWords[0].toLowerCase());

      if (firstIndex === -1) {
        return <>{text}</>;
      }

      return (
        <>
          {stringText.substring(0, firstIndex)}
          <mark>{stringText.substring(firstIndex, firstIndex + 1)}</mark>
          {stringText.substring(firstIndex + 1)}
        </>
      );
    }

    // For multi-character search terms, use the original approach
    return (
      <>
        {String(text)
          .split(regex)
          .map((part, index) =>
            searchWords.some(
              (word) => part.toLowerCase() === word.toLowerCase(),
            ) ? (
              <mark key={index}>{part}</mark>
            ) : (
              part
            ),
          )}
      </>
    );
  },
  (prevProps, nextProps) => prevProps.text === nextProps.text,
);

HighlightedText.displayName = "HighlightedText";

export const DefaultCellComponent = <T extends object>({
  row,
  accessorKey,
}: RenderCellProps<T>) => {
  if (!accessorKey.includes("|")) {
    return <HighlightedText text={getPath(row.original, accessorKey)} />;
  }
  const keys = accessorKey.split("|");
  const values = keys
    .map((key) => getPath(row.original, key))
    .filter((value) => value !== null && value !== undefined);
  return <HighlightedText text={values.join(" ") || ""} />;
};
