import * as React from "react";

import { TimeCard } from "@/components/TimeCard";
import { Badge, Button, ButtonIconLeft } from "@/components/ui";
import { cn, dateFormat } from "@/lib/utils";
import { AssetField, AssetOverviewCustomQuestions } from "@/types";

export const SafetyPageAssetField = ({
  field,
  response,
}: {
  field: AssetField;
  response: AssetOverviewCustomQuestions | undefined;
}) => {
  if (typeof response?.value === "undefined") {
    return null;
  }

  if (field?.response_type === "yesno" || typeof response.value === "boolean") {
    return response.value ? "True" : "False";
  }

  const responseValue = String(response.value);

  const selectedOptions = field.properties?.options?.find(
    (option) =>
      responseValue.split(",").includes(String(option.value)) ||
      responseValue === option.value,
  );

  if (field.response_type === "date") {
    return <TimeCard date={responseValue} formatString={dateFormat} />;
  }

  switch (field.properties?.safetyPageSettings?.displayAs) {
    case "date": {
      return <TimeCard date={responseValue} formatString={dateFormat} />;
    }
    case "badge": {
      if (!selectedOptions) {
        return null;
      }
      return responseValue.split(",").map((rValue) => {
        const displayInline =
          field.properties?.safetyPageSettings?.displayInline || false;
        const value = field.properties?.safetyPageSettings?.prependValue
          ? `${field.properties?.safetyPageSettings?.prependValue} - ${selectedOptions?.label}`
          : rValue || "";
        return (
          <Badge
            key={rValue}
            className={displayInline ? "me-2" : ""}
            color={selectedOptions?.colour || "green"}
            variant="contained"
            label={value}
            showDot={field.properties?.safetyPageSettings?.showDot}
            icon={
              field.properties?.safetyPageSettings?.showIcon
                ? selectedOptions?.icon || "fa-dot-circle"
                : undefined
            }
          />
        );
      });
    }
    case "link": {
      const label =
        field.properties?.safetyPageSettings.linkLabel || field.field;
      const icon = field.properties?.safetyPageSettings.icon || false;
      const link = field.properties?.safetyPageSettings.linkValue?.replace(
        "${value}",
        responseValue,
      );
      const element = icon ? (
        <ButtonIconLeft icon={icon} className={"w-full"}>
          {label}
        </ButtonIconLeft>
      ) : (
        <Button className={"w-full"}>{label}</Button>
      );
      return (
        <a href={link} target={"_blank"}>
          {element}
        </a>
      );
    }
    case "image": {
      return (
        <div className={""}>
          <img
            className={"h-full w-full border object-contain"}
            src={responseValue}
            alt={field.field}
          />
        </div>
      );
    }
    default: {
      const showLabel = field.properties?.safetyPageSettings?.showLabel;
      const label =
        field.properties?.safetyPageSettings?.labelOverride || field.field;
      const suffix = field.properties?.suffix;
      const value =
        field.response_type === "number"
          ? Number(responseValue).toLocaleString()
          : responseValue;
      return (
        <div className={cn("text-sm", showLabel && "flex justify-between")}>
          {showLabel && <span>{label}</span>}
          <span>{value + (suffix ? " " + suffix : "")}</span>
        </div>
      );
    }
  }
};
