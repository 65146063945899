import { router } from "@inertiajs/react";

import { AsbestosSampleCell } from "@/components/AsbestosSampleCell";
import { getPath } from "@/lib/utils";

import { RenderCellProps } from "./cell-types";

export const AsbestosSampleSheetCellComponent = <T extends object>({
  row,
  cellInfo,
  accessorKey,
}: RenderCellProps<T>) => {
  if (cellInfo.type !== "ASBESTOS_SAMPLE_SHEET") {
    return null;
  }
  const barcode = getPath(row.original, accessorKey);
  const sampleId = getPath(row.original, cellInfo.metadata.sampleIdAccessor);
  const auditId = getPath(row.original, cellInfo.metadata.auditIdAccessor);

  if (!barcode) {
    return "";
  }

  return (
    <AsbestosSampleCell
      barcode={barcode}
      sampleId={sampleId}
      auditId={auditId}
      onDone={() => {
        router.reload({
          only: ["tableData"],
        });
      }}
    />
  );
};
