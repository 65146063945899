import { RowSelectionState } from "@tanstack/react-table";
import React from "react";

import { DataTable } from "@/components/DataTable";
import {
  Badge,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@/components/ui";
import { Asset } from "@/types";

const AssetSelectDialog = (props: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  value: Record<string, Asset>;
  onValueChange: (value: { old_asset_id: string; label: string }[]) => void;
}) => {
  return (
    <Dialog open={props.open} onOpenChange={props.onOpenChange}>
      <DialogContent className="flex h-[75%] w-[75%] flex-col gap-0">
        <DialogTitle className="hidden">Select Assets</DialogTitle>
        <div className="flex h-full flex-col">
          <DataTable
            tableName={"assetTable"}
            routeName="api.assets.index"
            routeParams={{
              selectedIds: Object.values(props.value)
                .map((item) => item.old_asset_id)
                .join(","),
            }}
            getRowId={(originalRow: Asset) =>
              `${originalRow.old_asset_id}|${originalRow.label}`
            }
            selection={{
              enabled: true,
              isRowEnabled: () => true,
              prefill: Object.values(props.value).map(
                (item) => `${item.old_asset_id}|${item.label}`,
              ),
              bulkActions: (
                rowSelectionState: RowSelectionState,
                onDone: () => void,
              ) => (
                <Button
                  onClick={() => {
                    onDone();
                    const assets = Object.keys(rowSelectionState).map((key) => {
                      const [old_asset_id, label] = key.split("|");
                      return {
                        value: old_asset_id,
                        old_asset_id,
                        label,
                      };
                    });

                    props.onValueChange(assets);
                    props.onOpenChange(false);
                  }}
                >
                  Save
                </Button>
              ),
            }}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

type AssetSelectProps = {
  value: Record<string, Asset>;
  onChange: (value: { old_asset_id: string; label: string }[]) => void;
};

export const AssetSelect = (props: AssetSelectProps) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);

  return (
    <div className={"flex border pb-2 pl-2"}>
      <div className="mt-2 inline-flex flex-1 flex-wrap">
        {Object.values(props.value).map((r) => (
          <Badge color={"transparent"} key={r.old_asset_id} className="mr-2">
            {r.label}
          </Badge>
        ))}
      </div>
      <div className={"mt-2 w-10"}>
        <IconButton
          icon={"fa-pencil"}
          variant={"secondary"}
          onClick={(e) => {
            e.preventDefault();
            setDialogOpen(true);
          }}
        />
      </div>
      <AssetSelectDialog
        open={dialogOpen}
        onOpenChange={setDialogOpen}
        value={props.value}
        onValueChange={props.onChange}
      />
    </div>
  );
};
