import axios from "axios";
import { memo, useCallback, useState } from "react";
import { toast } from "sonner";

import { Button } from "@/components/ui/button";
import { buttonVariants } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { DocumentList } from "@/types";

interface FileDownloadCellProps {
  listItems: DocumentList[];
}

const FileDownloadCell: React.FC<FileDownloadCellProps> = ({ listItems }) => {
  return (
    <div className="flex space-x-2">
      {listItems.map((item) => (
        <DownloadButton key={`${item.type}-${item.url}`} item={item} />
      ))}
    </div>
  );
};

const DownloadButton = memo(({ item }: { item: DocumentList }) => {
  const [conversionRequested, setConversionRequested] = useState(false);

  const convertDocument = useCallback(async () => {
    if (conversionRequested || item.label !== "Convert to PDF") return;

    setConversionRequested(true);
    await axios.post(item.url);
    toast.success("Document queued for conversion");
  }, [conversionRequested, item]);

  if (item.type === "CONVERT") {
    return (
      <Button
        variant="secondary"
        onClick={convertDocument}
        disabled={conversionRequested || item.label !== "Convert to PDF"}
      >
        {conversionRequested ? "Queued" : item.label}
      </Button>
    );
  }

  if (item.disabled) {
    return (
      <Tooltip>
        <TooltipTrigger asChild>
          <Button disabled className={buttonVariants({ variant: "secondary" })}>
            {item.label}
          </Button>
        </TooltipTrigger>
        <TooltipContent side="top" align="center">
          {item.tooltip}
        </TooltipContent>
      </Tooltip>
    );
  }

  return (
    <a
      className={buttonVariants({ variant: "secondary" })}
      href={item.url}
      target="_blank"
      rel="noopener noreferrer"
    >
      {item.label}
    </a>
  );
});

DownloadButton.displayName = "DownloadButton";

export default memo(FileDownloadCell);
