import { AsbestosSampleSheetCellComponent } from "./asbestos-sample-sheet-cell-component";
import { AssetFieldBadgeCellComponent } from "./asset-field-badge-cell-component";
import { AuditAssetDropdownMenuCellComponent } from "./audit-asset-dropdown-menu-cell-component";
import { AuditBadgeCellComponent } from "./audit-badge-cell-component";
import { RenderCellProps } from "./cell-types";
import { DateTimeCellComponent } from "./date-time-cell-component";
import { DefaultCellComponent } from "./default-cell-component";
import { FileDownloadCellComponent } from "./file-download-cell-component";
import { LinkCellComponent } from "./link-cell-component";
import { LinkCountCellComponent } from "./link-count-cell-component";
import { RiskBadgeCellComponent } from "./risk-badge-cell-component";

export const RenderCell = <T extends object>(props: RenderCellProps<T>) => {
  const renderCell = () => {
    switch (props.cellInfo.type) {
      case "FILE_DOWNLOAD": {
        return <FileDownloadCellComponent {...props} />;
      }

      case "ASSET_FIELD_BADGE": {
        return <AssetFieldBadgeCellComponent {...props} />;
      }

      case "RISK_BADGE": {
        return <RiskBadgeCellComponent {...props} />;
      }

      case "AUDIT_BADGE": {
        return <AuditBadgeCellComponent {...props} />;
      }

      case "ASBESTOS_SAMPLE_SHEET": {
        return <AsbestosSampleSheetCellComponent {...props} />;
      }

      case "AUDIT_ASSET_DROPDOWN_MENU": {
        return <AuditAssetDropdownMenuCellComponent {...props} />;
      }

      case "LINK_COUNT": {
        return <LinkCountCellComponent {...props} />;
      }

      case "LINK": {
        return <LinkCellComponent {...props} />;
      }

      case "DATE_TIME": {
        return <DateTimeCellComponent {...props} />;
      }

      default: {
        return <DefaultCellComponent {...props} />;
      }
    }
  };
  return <>{renderCell()}</>;
};
