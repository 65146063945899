import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useForm, useFormContext, useWatch } from "react-hook-form";
import { toast } from "sonner";
import { useDebouncedCallback } from "use-debounce";

import { axios } from "@/axios";
import { FormFieldWrapper, FormTextInput } from "@/components/DynamicForm";
import { config } from "@/lib/constants";
import { Document, Resource } from "@/types";

import { FormFileUploadInput } from "./FileUpload";
import { SampleResultMultiSelect } from "./SampleResultMultiSelect";
import {
  Badge,
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Label,
  RadioGroup,
  RadioGroupItem,
  Separator,
} from "./ui";

type ApplyResultsFormData = {
  recordIds: number[];
  results: number[];
  file: {
    file_type: "new" | "old" | "none";
    file_id?: string;
    new_file?: File[];
  };
  lab_cert_number: string;
};

export const ApplyResultsForm = ({
  records,
  onDone,
}: {
  records: { recordId: string; version: string }[];
  onDone: () => void;
}) => {
  const form = useForm<ApplyResultsFormData>({
    defaultValues: {
      recordIds: records.map((r) => Number(r.recordId)),
      file: { file_type: "none" },
      lab_cert_number: "",
    },
  });

  const onSubmit = async (values: ApplyResultsFormData) => {
    if (values.file.file_type === "none") {
      const data = {
        recordIds: values.recordIds,
        resultIds: values.results,
        lab_cert_number: values.lab_cert_number,
      };
      await axios.post(route("api.apply-result"), data);
    }
    if (values.file.file_type === "old") {
      const data = {
        recordIds: values.recordIds,
        resultIds: values.results,
        file_id: values.file.file_id,
        lab_cert_number: values.lab_cert_number,
      };
      await axios.post(route("api.apply-result"), data);
    }
    if (values.file.file_type === "new") {
      const file = values.file.new_file as File[];
      const formData = new FormData();
      formData.append("recordIds", values.recordIds.join(","));
      formData.append("resultIds", values.results.join(","));
      formData.append("file", file[0], file[0].name);
      formData.append("lab_cert_number", values.lab_cert_number);
      await axios.post(route("api.apply-result"), formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    }
    onDone();
    toast("Results Applied");
  };

  return (
    <Form {...form}>
      <div>
        <div>
          <Label>Records</Label>
        </div>
        <div className="inline-flex flex-wrap">
          {records.map((r) => (
            <Badge color={"black"} key={r.recordId} className="mr-2 mt-2">
              {r.version}
            </Badge>
          ))}
        </div>
      </div>
      <Separator className="my-4" />
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="results"
          defaultValue={[]}
          rules={{ required: "Please add at least one result" }}
          render={({ field }) => {
            return (
              <FormItem className="flex flex-col">
                <FormLabel>Results</FormLabel>
                <SampleResultMultiSelect
                  value={field.value}
                  onChange={field.onChange}
                />
                <FormMessage />
              </FormItem>
            );
          }}
        />
        <FormFieldWrapper
          fieldInfo={{
            label: "CoA / Lab cert #",
            name: "lab_cert_number",
            accessorKey: "lab_cert_number",
            type: { input: "TEXT" },
          }}
        >
          <FormTextInput
            name={"lab_cert_number"}
            label={"CoA / Lab cert #"}
            accessorKey={"lab_cert_number"}
          />
        </FormFieldWrapper>
        <ApplyResultsFileField name="file" />
        {!form.formState.isSubmitting ? (
          <Button type="submit">Submit</Button>
        ) : (
          <Button type="button" disabled>
            Please wait...
          </Button>
        )}
      </form>
    </Form>
  );
};

export const ApplyResultsFileField = ({ name }: { name: string }) => {
  const { control, formState, setValue } = useFormContext();
  const file_type = useWatch({
    name: `${name}.file_type`,
    control: control,
  });
  const initialFileId = formState.defaultValues?.file?.file_id;
  const [order, setOrder] = useState("recent");
  const [inputValue, setInputValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = useDebouncedCallback((value: string) => {
    setSearchQuery(value);
  }, 500);

  const { data: recentFiles, isLoading } = useQuery({
    queryKey: ["recent-files", initialFileId, order, searchQuery],
    queryFn: async () => {
      const response = await axios.get<Resource<Document[]>>(
        `/api/documents/all?uploadid=12&include_id=${initialFileId || ""}&order_by=${order || ""}&search=${searchQuery || ""}`,
      );

      let data = response.data.data;

      if (initialFileId) {
        data = data.sort((a, b) => {
          if (a.id.toString() === initialFileId) return -1;
          if (b.id.toString() === initialFileId) return 1;
          return 0;
        });
      }

      return data;
    },
  });

  return (
    <div className="space-y-4">
      <FormField
        control={control}
        name={`${name}.file_type`}
        render={({ field }) => (
          <FormItem className="space-y-3">
            <FormLabel>File</FormLabel>
            <FormControl>
              <RadioGroup
                onValueChange={(value) => {
                  field.onChange(value);
                  if (value === "none") {
                    setValue("file.file_id", null);
                  }
                }}
                defaultValue={field.value}
                className="grid grid-cols-2 gap-4"
              >
                <FormItem>
                  <FormLabel className="flex items-center space-x-2 rounded-md bg-secondary-50 px-4 py-2 [&:has([data-state=checked])]:bg-primary-100">
                    <FormControl>
                      <RadioGroupItem value="none" />
                    </FormControl>
                    <span>No File</span>
                  </FormLabel>
                </FormItem>
                <FormItem>
                  <FormLabel className="flex items-center space-x-2 rounded-md bg-secondary-50 px-4 py-2 [&:has([data-state=checked])]:bg-primary-100">
                    <FormControl>
                      <RadioGroupItem value="new" />
                    </FormControl>
                    <span>Upload File</span>
                  </FormLabel>
                </FormItem>
                <FormItem>
                  <FormLabel className="flex items-center space-x-2 rounded-md bg-secondary-50 px-4 py-2 [&:has([data-state=checked])]:bg-primary-100">
                    <FormControl>
                      <RadioGroupItem value="old" />
                    </FormControl>
                    <span>Link File</span>
                  </FormLabel>
                </FormItem>
              </RadioGroup>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      {file_type === "old" && (
        <FormField
          control={control}
          name={`${name}.file_id`}
          render={({ field }) => (
            <FormItem className="space-y-3">
              <FormLabel>Select a file</FormLabel>
              <div className="flex flex-col gap-y-2">
                <div className="flex items-center justify-between">
                  <span
                    onClick={() =>
                      setOrder((prevOrder) =>
                        prevOrder === "recent" ? "az" : "recent",
                      )
                    }
                    className="inline-block cursor-pointer text-xs text-blue-500"
                  >
                    Sort: {order === "recent" ? "Most recent" : "A-Z"}
                  </span>
                </div>
                <Input
                  type="text"
                  placeholder="Search files"
                  value={inputValue}
                  onChange={(e) => {
                    const value = e.target.value;
                    setInputValue(value);
                    handleSearch(value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      setSearchQuery(inputValue);
                    }
                  }}
                />
              </div>
              <FormControl
                className={
                  (recentFiles?.length ?? 0) > 8
                    ? "max-h-[500px] overflow-y-auto"
                    : ""
                }
              >
                {isLoading ? (
                  <div className="mt-4 flex items-center justify-center">
                    <span className="text-sm text-body-text-subtle">
                      Loading files ...
                    </span>
                  </div>
                ) : (
                  <RadioGroup
                    onValueChange={field.onChange}
                    value={field.value}
                    className="flex flex-col space-y-1"
                  >
                    {recentFiles?.length === 0 ? (
                      <div className="flex items-center justify-center">
                        <span className="text-sm text-body-text-subtle">
                          No documents found
                        </span>
                      </div>
                    ) : (
                      recentFiles?.map((d) => (
                        <FormItem key={d.id}>
                          <FormLabel className="flex items-center space-x-2 rounded-md bg-secondary-50 px-4 py-2 [&:has([data-state=checked])]:bg-primary-100">
                            <FormControl>
                              <RadioGroupItem
                                value={d.id.toString()}
                                onClick={(e) => {
                                  if (
                                    e.currentTarget.getAttribute(
                                      "data-state",
                                    ) === "checked"
                                  ) {
                                    field.onChange(null);
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </FormControl>
                            <span className="flex flex-1 items-center justify-between">
                              <span>{d.display_name}</span>
                              <Button asChild type="button">
                                <a target="_blank" href={d.downloads[0].url}>
                                  View
                                </a>
                              </Button>
                            </span>
                          </FormLabel>
                        </FormItem>
                      ))
                    )}
                  </RadioGroup>
                )}
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      )}
      {file_type === "new" && (
        <FormFileUploadInput
          control={control}
          name={`${name}.new_file`}
          fileUploadProps={{
            maxSize: config.MAX_MB_UPLOAD.DOCUMENT,
            maxFiles: 1,
            accept: { pdf: ["application/pdf"] },
          }}
          rules={{ required: "Please select a file" }}
        />
      )}
    </div>
  );
};
