import { router } from "@inertiajs/react";
import { ColumnOrderState, VisibilityState } from "@tanstack/react-table";
import { createContext, useContext } from "react";
import isEqual from "react-fast-compare";
import { createStore, useStore } from "zustand";

// import { devtools } from "zustand/middleware";

import {
  PaginatedResource,
  PaginatedResourceColumn,
} from "@/types/common-types";

import { SelectedFilter } from "./filters";

type TableConfigState = {
  setSearch: (search: string) => void;
  setPage: (page: number) => void;
  setFilters: (filters: SelectedFilter[]) => void;
  setOrder: ({
    order_by,
    order_direction,
  }: {
    order_by?: string;
    order_direction?: string;
  }) => void;
  setLimit: (limit: number) => void;
  toggleDisplayFlag: (key: string, value: boolean) => void;
  resetColumnVisibility: () => void;
  toggleColumnVisibility: (column: PaginatedResourceColumn) => void;
  updateColumnOrder: (newColumnOrder: ColumnOrderState) => void;
  resetColumnDefaults: () => void;
} & {
  // state
  search: string;
  order_by: string;
  order_direction: string;
  limit: number;
  page: number;
  displayFlags: Record<string, "true" | "false">;
  columnVisibility: VisibilityState;
  columnOrder: ColumnOrderState;
  filters: SelectedFilter[];

  // defaults
  defaultColumnOrder: ColumnOrderState;
  defaultColumnVisibility: VisibilityState;
  defaultOrderBy: PaginatedResource<unknown>["defaultOrderBy"];
  defaultOrderDirection: PaginatedResource<unknown>["defaultOrderDirection"];
  defaultPageLimit: PaginatedResource<unknown>["defaultPageLimit"];

  // options
  allDisplayFlags: PaginatedResource<unknown>["displayFlags"];
  allFilters: PaginatedResource<unknown>["filters"];
  pageLimits: PaginatedResource<unknown>["pageLimits"];
  savePreferencesAs: PaginatedResource<unknown>["savePreferencesAs"];
  tableAlias: PaginatedResource<unknown>["tableAlias"];

  // data
  uniqueColumns: PaginatedResourceColumn[];
  sortableColumns: PaginatedResourceColumn[];
};

export const createTableConfigStore = <TData>(
  tableData: PaginatedResource<TData>,
  updateParams?: (params: unknown) => void,
) => {
  const getTableParams = () => {
    if (updateParams) {
      return {};
    }
    if (tableData.tableAlias) {
      return route().params[tableData.tableAlias] || {};
    }
    return route().params;
  };

  const uniqueColumns = tableData.columns.filter(
    (column, index, self) =>
      index === self.findIndex((c) => c.header === column.header),
  );

  const defaultColumnVisibility = uniqueColumns.reduce((acc, column) => {
    acc[column.header] = !column.hidden;
    return acc;
  }, {} as VisibilityState);
  const getColumnVisibility = (columns: string[]) => {
    const columnVisibility = columns.reduce(
      (acc, column) => {
        if (column.startsWith("-")) {
          acc[column.slice(1)] = false;
        } else {
          acc[column] = true;
        }
        return acc;
      },
      {} as Record<string, boolean>,
    );
    return columnVisibility;
  };

  const defaultColumnOrder = uniqueColumns.map((column) => column.header);
  const getColumnOrder = (columns: string[]) => {
    return [
      ...columns.map((column) =>
        column.startsWith("-") ? column.slice(1) : column,
      ),
    ];
  };

  const getInitialParams = () => {
    const routeParams = getTableParams();
    const {
      search = "",
      order = tableData.orderBy,
      limit = tableData.defaultPageLimit,
      filters = {},
      page = 1,
      columns = {},
      ...displayFlags
    } = routeParams as {
      search: string;
      order: string;
      limit: string;
      page: string;
      filters: Record<
        string,
        { condition: "equals" | "not_equals"; values: string[] }
      >;
      columns: Record<number, string>;
    };
    const columnsArray = Object.values(columns);
    return {
      search,
      order_by: order ? (order[0] === "-" ? order.slice(1) : order) : "",
      order_direction: order ? (order[0] === "-" ? "desc" : "asc") : "",
      limit: Number(limit),
      page: Number(page),
      displayFlags: displayFlags as TableConfigState["displayFlags"],
      filters: Object.keys(filters).map((key) => ({
        key,
        condition: filters[key].condition,
        values: filters[key].values,
      })),
      defaultColumnVisibility,
      columnVisibility: columnsArray.length
        ? getColumnVisibility(columnsArray)
        : defaultColumnVisibility,
      defaultColumnOrder,
      columnOrder: columnsArray.length
        ? getColumnOrder(columnsArray)
        : defaultColumnOrder,
    };
  };
  return createStore<TableConfigState>()((set, get) => {
    const reloadPage = (client = false) => {
      const { displayFlags, ...rest } = get();
      if (updateParams) {
        updateParams({
          search: rest.search,
          order_by: rest.order_by,
          order_direction: rest.order_direction,
          limit: rest.limit,
          filters: rest.filters,
          page: rest.page,
          columns: rest.columnOrder,
          columnVisibility: rest.columnVisibility,
          displayFlags,
        });
        return;
      }
      const nextRouteParams: Record<string, unknown> = {
        ...displayFlags,
      };
      if (rest.limit && rest.limit !== tableData.defaultPageLimit) {
        nextRouteParams.limit = rest.limit;
      }

      if (rest.page !== 1) {
        nextRouteParams.page = rest.page;
      }

      if (
        rest.order_by !== tableData.defaultOrderBy ||
        rest.order_direction !== tableData.defaultOrderDirection
      ) {
        nextRouteParams.order =
          rest.order_direction === "asc" ? rest.order_by : `-${rest.order_by}`;
      }

      if (rest.search !== "") {
        nextRouteParams.search = rest.search;
      }

      if (rest.filters.length > 0) {
        nextRouteParams.filters = rest.filters.reduce(
          (acc, filter) => {
            acc[filter.key] = {
              condition: filter.condition,
              values: filter.values,
            };
            return acc;
          },
          {} as Record<string, { condition: string; values: string[] }>,
        );
      }

      if (
        !isEqual(rest.columnOrder, rest.defaultColumnOrder) ||
        !isEqual(rest.columnVisibility, rest.defaultColumnVisibility)
      ) {
        nextRouteParams.columns = rest.columnOrder.map((column) => {
          if (rest.columnVisibility[column]) {
            return column;
          }
          return `-${column}`;
        });
      }

      if (!isEqual(nextRouteParams, getTableParams())) {
        if (client) {
          router.replace({
            url: route(
              route().current() as string,
              tableData.tableAlias
                ? {
                    ...route().params,
                    [tableData.tableAlias]: nextRouteParams,
                  }
                : nextRouteParams,
            ),
            preserveState: true,
            preserveScroll: true,
          });
        } else {
          router.get(
            route(
              route().current() as string,
              tableData.tableAlias
                ? {
                    ...route().params,
                    [tableData.tableAlias]: nextRouteParams,
                  }
                : nextRouteParams,
            ),
            {},
            {
              only: [tableData.tableAlias ? tableData.tableAlias : "tableData"],
              preserveState: true,
              replace: true,
            },
          );
        }
      }
    };
    return {
      allDisplayFlags: tableData.displayFlags,
      defaultOrderBy: tableData.defaultOrderBy,
      defaultOrderDirection: tableData.defaultOrderDirection,
      defaultPageLimit: tableData.defaultPageLimit,
      pageLimits: tableData.pageLimits,
      tableAlias: tableData.tableAlias,
      savePreferencesAs: tableData.savePreferencesAs,
      allFilters: tableData.filters,
      uniqueColumns,
      sortableColumns: uniqueColumns.filter((column) => column.sortable),
      ...getInitialParams(),
      setSearch: (newSearch) => {
        set({ search: newSearch });
        reloadPage();
      },
      setPage: (newPage) => {
        set({ page: newPage });
        reloadPage();
      },
      setFilters: (newFilters) => {
        set({ filters: newFilters });
        reloadPage();
      },
      setOrder: ({ order_by, order_direction }) => {
        set({
          order_by: order_by || get().order_by,
          order_direction: order_direction || get().order_direction,
        });
        reloadPage();
      },
      setLimit: (newLimit) => {
        set({ limit: newLimit });
        reloadPage();
      },
      toggleDisplayFlag: (key, value) => {
        set({
          displayFlags: {
            ...get().displayFlags,
            [key]: String(value) as "true" | "false",
          },
        });
        reloadPage();
      },
      toggleColumnVisibility: (column) => {
        set({
          columnVisibility: {
            ...get().columnVisibility,
            [column.header]: !get().columnVisibility[column.header],
          },
        });
        reloadPage(true);
      },
      resetColumnVisibility: () => {
        set({
          columnVisibility: get().defaultColumnVisibility,
          columnOrder: get().defaultColumnOrder,
        });
        reloadPage(true);
      },
      updateColumnOrder: (newColumnOrder) => {
        set({ columnOrder: newColumnOrder });
        reloadPage(true);
      },
      resetColumnDefaults: () => {
        set({
          defaultColumnVisibility: get().columnVisibility,
          defaultColumnOrder: get().columnOrder,
        });
        reloadPage(true);
      },
    };
  });
};

export type TableConfigStore<TData> = ReturnType<
  typeof createTableConfigStore<TData>
>;

export const TableConfigContext =
  createContext<TableConfigStore<unknown> | null>(null);

export function useTableConfig<T, U>(
  selector: (state: TableConfigState) => U,
): U {
  const store = useContext(TableConfigContext) as ReturnType<
    typeof createTableConfigStore<T>
  > | null;
  if (!store)
    throw new Error("Missing TableConfigContext.Provider in the tree");
  return useStore(store, selector);
}
