import { type VariantProps, cva } from "class-variance-authority";
import * as React from "react";

import { Kbd } from "@/components/kbd";
import { cn } from "@/lib/utils";

import { Icon } from "../Icon";

const inputVariants = cva(
  "flex w-full rounded border-1 text-sm focus:outline-0 focus:ring-0 focus:border disabled:cursor-not-allowed",
  {
    variants: {
      variant: {
        primary: cn(
          "bg-body-surface-default border-body-border-default text-body-text-default placeholder:text-body-text-x-subtle",
          "hover:bg-body-surface-light hover:border-body-border-m1 hover:text-body-text-x-subtle hover:placeholder:text-body-text-x-subtle",
          "focus:bg-body-surface-default focus:border-body-color-accent-default focus:text-body-text-default focus:placeholder:text-body-text-x-subtle",
          "disabled:bg-body-surface-dark disabled:border-body-border-default disabled:text-body-text-x-subtle disabled:placeholder:text-body-text-x-subtle",
        ),
        danger: cn(
          "bg-body-surface-danger-default border-body-border-danger text-body-text-danger-default placeholder:text-body-text-danger-default",
          "hover:bg-body-surface-danger-default hover:border-body-border-dangerous hover:text-body-text-danger-dangerous hover:placeholder:text-body-text-danger-dangerous",
          "focus:bg-body-surface-default focus:border-body-border-danger focus:text-body-text-default focus:placeholder:text-body-default-danger",
          "disabled:bg-body-surface-dark disabled:border-body-border-default disabled:text-body-text-x-subtle disabled:placeholder:text-body-text-x-subtle",
        ),
      },
    },
    defaultVariants: {
      variant: "primary",
    },
  },
);

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof inputVariants> {
  kbd?: string;
}

// const octfolioStyles = "flex h-10 w-full rounded-md border border-body-border-default bg-body-surface-default px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-body-text-x-subtle focus-visible:outline-none focus-visible:ring-0 disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-body-surface-dark disabled:border-body-border-default disabled:text-body-text-x-subtle disabled:placeholder:text-body-text-x-subtle";

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, variant, size, kbd, ...props }, ref) => {
    return (
      <div className={"group relative h-8 w-full"}>
        <Kbd kbd={kbd} />
        <input
          className={cn(
            "peer flex h-9 w-full rounded-md border border-body-border-default bg-body-surface-default px-3 py-1 text-sm text-body-text-default shadow-sm placeholder:text-body-text-x-subtle focus:border focus:outline-0 focus:ring-0 disabled:cursor-not-allowed",
            className,
          )}
          ref={ref}
          {...props}
        />
      </div>
    );
  },
);
Input.displayName = "Input";

export interface InputIconProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  icon: string;
  className?: string;
  variant?: "primary" | "danger";
}

const InputIconLeft = React.forwardRef<HTMLDivElement, InputIconProps>(
  ({ icon, className, variant, ...props }, ref) => {
    return (
      <div
        className={cn(
          inputVariants({ variant }),
          "flex items-center border-none",
          className,
        )}
        ref={ref}
      >
        <div className="absolute items-center pl-2">
          <Icon
            icon={icon}
            className={cn(
              "h-4 w-4 !bg-transparent",
              inputVariants({ variant }),
            )}
          />
        </div>
        <input
          {...props}
          className={cn(
            inputVariants({ variant }),
            "bg-transparent pl-7",
            className,
          )}
        />
      </div>
    );
  },
);

InputIconLeft.displayName = "InputLeft";

const InputIconRight = React.forwardRef<
  HTMLDivElement,
  InputIconProps & {
    iconOnClick?: () => void;
  }
>(({ icon, className, variant, iconOnClick, ...props }, ref) => {
  return (
    <div
      className={cn(
        inputVariants({ variant }),
        "relative flex items-center border-none",
        className,
      )}
      ref={ref}
    >
      <div className="absolute right-0 flex items-center pr-2">
        <Icon
          icon={icon}
          onClick={iconOnClick}
          className={cn("h-4 w-4 !bg-transparent", inputVariants({ variant }))}
        />
      </div>
      <input
        {...props}
        className={cn(
          inputVariants({ variant }),
          "bg-transparent pr-7",
          className,
        )}
      />
    </div>
  );
});

InputIconRight.displayName = "InputRight";

export { Input, InputIconLeft, InputIconRight };
