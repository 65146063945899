import { useTheme } from "next-themes";
import { Toaster as Sonner } from "sonner";

import { Button } from "@/components/ui/button";

type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = ({ ...props }: ToasterProps) => {
  const { theme = "system" } = useTheme();

  return (
    <Sonner
      theme={theme as ToasterProps["theme"]}
      className="toaster group"
      toastOptions={{
        classNames: {
          content: "flex-1",
          toast:
            "group toast group-[.toaster]:bg-black group-[.toaster]:text-white dark:group-[.toaster]:bg-background dark:group-[.toaster]:text-foreground group-[.toaster]:border-none group-[.toaster]:shadow-lg",
          description: "group-[.toast]:text-muted-foreground",
          actionButton:
            "group-[.toast]:bg-primary group-[.toast]:text-primary-foreground",
          cancelButton:
            "group-[.toast]:bg-muted group-[.toast]:text-muted-foreground",
        },
      }}
      {...props}
    />
  );
};

const ToastAction = (props: { label?: string; onClick: () => void }) => {
  return (
    <div className={"shrink-0 text-end"}>
      <Button
        onClick={props.onClick}
        variant={"tertiary"}
        className={"cursor-pointer text-white"}
      >
        {props.label || "View"}
      </Button>
    </div>
  );
};

export { ToastAction, Toaster };
