import { format, startOfMonth } from "date-fns";
import * as React from "react";
import { useState } from "react";

import { Icon } from "@/components/Icon";
import { ErrorHelperText } from "@/components/forms/elements/shared";
import {
  Button,
  Calendar,
  Label,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui";
import { cn, dateFormat } from "@/lib/utils";

export const FormDate = ({
  placeholder = "Pick a date",
  ...props
}: {
  label?: string;
  value: Date | null;
  error?: string;
  setDate: (date: Date) => void;
  helpText?: string;
  disabled?: boolean;
  placeholder?: string;
}) => {
  const [dobDropdownOpen, setDobDropdownOpen] = useState(false);

  const [monthSelected, setMonthFrom] = useState<Date>(
    startOfMonth(props?.value || new Date()),
  );

  const [yearSelected, setYearFrom] = useState<number | undefined>(
    props.value?.getFullYear?.() || new Date().getFullYear(),
  );

  const _setMonth = (date: Date) => {
    setMonthFrom(date);
    setYearFrom(date.getFullYear());
  };

  const handleMonthChange = (month: number) => {
    if (monthSelected) {
      const newDate = new Date(monthSelected);
      newDate.setMonth(month);
      setMonthFrom(newDate);
    }
  };

  const handleYearChange = (year: number) => {
    if (yearSelected) {
      const newDate = new Date(monthSelected || new Date());
      newDate.setFullYear(year);
      setYearFrom(year);
      setMonthFrom(newDate);
    }
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const years = Array.from(
    { length: 201 },
    (_, i) => new Date().getFullYear() - 100 + i,
  ).reverse();
  return (
    <div className={"flex flex-col"}>
      {props.label && (
        <Label className="pb-1 text-foreground">{props.label}</Label>
      )}
      <Popover open={dobDropdownOpen} onOpenChange={setDobDropdownOpen}>
        <PopoverTrigger asChild>
          <Button
            disabled={props.disabled}
            variant={"secondary"}
            className={cn(
              "h-9 w-full rounded-none border-body-border-default bg-white py-1 pl-3 text-left font-normal hover:border-inherit hover:bg-inherit focus:text-body-text-default focus:placeholder:text-body-text-x-subtle [&[data-state='open']]:border-body-color-accent-default",
            )}
          >
            <span className="flex-1">
              {props.value ? format(props.value, dateFormat) : placeholder}
            </span>

            <Icon
              icon={"fa-calendar"}
              className={"ml-auto h-4 w-4 opacity-50"}
            />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <div className="mx-2 grid grid-cols-2 gap-2 pt-2">
            <Select
              disabled={props.disabled}
              onValueChange={(value) => {
                handleMonthChange(months.indexOf(value));
              }}
              value={
                monthSelected ? months[monthSelected.getMonth()] : undefined
              }
            >
              <SelectTrigger className="text-sm font-medium hover:bg-accent hover:text-accent-foreground focus:ring-0 focus:ring-offset-0">
                <SelectValue placeholder="Month" />
              </SelectTrigger>
              <SelectContent>
                {months.map((month, idx) => (
                  <SelectItem key={idx} value={month}>
                    {month}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <Select
              onValueChange={(value) => {
                handleYearChange(Number(value));
              }}
              value={yearSelected ? yearSelected.toString() : undefined}
            >
              <SelectTrigger className="font-medium hover:bg-accent hover:text-accent-foreground focus:ring-0 focus:ring-offset-0">
                <SelectValue placeholder="Year" />
              </SelectTrigger>
              <SelectContent>
                {years.map((year, idx) => (
                  <SelectItem key={idx} value={year.toString()}>
                    {year}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <Calendar
            fixedWeeks
            mode="single"
            month={monthSelected}
            onMonthChange={_setMonth}
            selected={props.value || undefined}
            onSelect={(date) => {
              if (date) {
                const year = date.getFullYear();
                const month = date.getMonth();
                const day = date.getDate();
                const newDate = new Date(Date.UTC(year, month, day, 0, 0, 0));
                props.setDate(newDate);
              }
              setDobDropdownOpen(false);
            }}
            initialFocus
          />
        </PopoverContent>
      </Popover>
      <ErrorHelperText {...props} />
    </div>
  );
};
