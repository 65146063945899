import { Link } from "@inertiajs/react";

import { getPath } from "@/lib/utils";

import { RenderCellProps } from "./cell-types";

export const LinkCellComponent = <T extends object>({
  row,
  accessorKey,
  cellInfo,
}: RenderCellProps<T>) => {
  if (cellInfo.type !== "LINK") {
    return null;
  }
  const label = getPath(row.original, accessorKey);
  const link = getPath(row.original, cellInfo.linkAccessorKey);
  const Comp = cellInfo.metadata.inertia ? Link : "a";
  const newWindow = cellInfo.metadata.new_tab ? "_blank" : "_self";
  return (
    <Comp href={link} target={newWindow} className={"hover:underline"}>
      {label}
    </Comp>
  );
};
