import { TimeCard } from "@/components/TimeCard";
import { getPath } from "@/lib/utils";

import { RenderCellProps } from "./cell-types";

export const DateTimeCellComponent = <T extends object>({
  row,
  accessorKey,
  cellInfo,
}: RenderCellProps<T>) => {
  if (cellInfo.type !== "DATE_TIME") {
    return null;
  }
  const value = getPath(row.original, accessorKey);
  return (
    <TimeCard
      date={value}
      align={"center"}
      formatString={cellInfo.metadata.format}
      pastDateInRed={cellInfo.metadata.pastDateInRed}
    />
  );
};
