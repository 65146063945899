import { useEffect, useState } from "react";

import { IconButton } from "@/components/ui/button";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { useLightboxStore } from "@/stores/use-lightbox-store";

const Lightbox = () => {
  const {
    isOpen,
    images: _images,
    currentIndex,
    close,
    next,
    prev,
    setIndex,
  } = useLightboxStore();
  const [isLoading, setIsLoading] = useState(true);
  const _currentImage = _images[currentIndex];

  const currentImage =
    typeof _currentImage === "string" ? { url: _currentImage } : _currentImage;

  const images = _images.map((image) =>
    typeof image === "string" ? { url: image } : image,
  );

  // Handle keyboard navigation
  useEffect(() => {
    if (!isOpen) return;

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "ArrowRight") next();
      if (e.key === "ArrowLeft") prev();
      if (e.key === "Escape") close();
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [isOpen, next, prev, close]);

  // Reset loading state when image changes
  useEffect(() => {
    setIsLoading(true);
  }, [currentIndex]);

  if (!isOpen || images.length === 0) return null;

  return (
    <Dialog open={isOpen} onOpenChange={(open) => !open && close()}>
      <DialogContent
        closeButton={false}
        className="w-[95vw] max-w-screen-lg bg-background/95 p-0 backdrop-blur-sm"
      >
        <div className="relative flex h-full flex-col">
          {/* Close button */}
          <IconButton
            className="absolute right-2 top-2 z-50"
            icon={"fa-xmark"}
            variant="tertiary"
            onClick={close}
          />

          {/* Main image */}
          <div className="relative flex flex-1 items-center justify-center p-4 md:p-8">
            <div className="relative flex h-full w-full items-center justify-center">
              {isLoading && (
                <div className="absolute inset-0 flex items-center justify-center">
                  <div className="h-8 w-8 animate-spin rounded-full border-4 border-primary border-t-transparent"></div>
                </div>
              )}
              <img
                src={currentImage.url}
                alt={currentImage.alt || `Image ${currentIndex + 1}`}
                width={currentImage.width || 1200}
                height={currentImage.height || 800}
                className={`max-h-[70vh] w-auto object-contain transition-opacity duration-300 ${
                  isLoading ? "opacity-0" : "opacity-100"
                }`}
                onLoad={() => setIsLoading(false)}
              />
            </div>

            {/* Navigation buttons */}
            {images.length > 1 && (
              <>
                <IconButton
                  icon={"fa-chevron-left"}
                  variant="tertiary"
                  className="absolute left-2 top-1/2 -translate-y-1/2"
                  onClick={prev}
                />
                <IconButton
                  icon={"fa-chevron-right"}
                  variant="tertiary"
                  className="absolute right-2 top-1/2 -translate-y-1/2"
                  onClick={next}
                />
              </>
            )}
          </div>

          {/* Thumbnails */}
          {images.length > 1 && (
            <div className="border-t p-2">
              <div className="flex items-center justify-center gap-2 overflow-x-auto py-2">
                {images.map((image, index) => (
                  <button
                    key={index}
                    className={`relative h-16 w-16 flex-shrink-0 overflow-hidden rounded-md border-2 transition-all ${
                      index === currentIndex
                        ? "border-primary"
                        : "border-transparent hover:border-primary/50"
                    }`}
                    onClick={() => setIndex(index)}
                  >
                    <img
                      src={image.url}
                      alt={image.alt || `Thumbnail ${index + 1}`}
                      className="object-cover"
                    />
                  </button>
                ))}
              </div>
              <div className="mt-1 text-center text-sm text-muted-foreground">
                {currentIndex + 1} / {images.length}
              </div>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export { Lightbox };
