//#region
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ColumnOrderState, VisibilityState } from "@tanstack/react-table";
import { useState } from "react";
import { toast } from "sonner";

import { axios } from "@/axios";
import { useTableNavigationStore } from "@/stores/record-navigation-store";
import { PaginatedResource, TODO } from "@/types";

import { LoadingSpinner } from "./LoadingSpinner";
import { CommonDataTableProps, SimpleDataTable } from "./data-table/data-table";
import {
  getGlobalTableSettingsFromStorage,
  getSettingsFromStorage,
} from "./data-table/data-table-utils";
import { DisplayOptionsParams } from "./data-table/display-options";
import { TableProvider } from "./data-table/table-config-provider";

//#endregion

const FALLBACK_PAGE_LIMIT = 50;

type DataTableProps<TData, TValue> = CommonDataTableProps<TData, TValue> & {
  routeName: string;
  routeParams?: Record<string, string>;
  extraFilters?: Record<string, unknown>[];
  syncWithURL?: boolean;
  refetchInterval?: number;
};

export const DataTable = <TData extends object, TValue>(
  props: DataTableProps<TData, TValue>,
) => {
  const queryClient = useQueryClient();
  const { setPreviousTableRouteObject, setPreviousTableData } =
    useTableNavigationStore();
  const [params, setParams] = useState(() => {
    return {
      search: "",
      order_by: getSettingsFromStorage({
        tableName: props.tableName,
        settingsName: "order_by",
      }),
      order_direction: getSettingsFromStorage({
        tableName: props.tableName,
        settingsName: "order_direction",
      }),
      limit:
        getGlobalTableSettingsFromStorage({
          settingsName: "limit",
        }) || FALLBACK_PAGE_LIMIT,
      filters: [],
      page: 1,
      displayFlags:
        getSettingsFromStorage({
          tableName: props.tableName,
          settingsName: "displayFlags",
        }) || ({} as DisplayOptionsParams["displayFlags"]),
      columnOrder:
        getSettingsFromStorage({
          tableName: props.tableName,
          settingsName: "columnOrder",
        }) || ([] as ColumnOrderState),
      columnVisibility:
        getSettingsFromStorage({
          tableName: props.tableName,
          settingsName: "columnVisibility",
        }) || ({} as VisibilityState),
    };
  });

  const queryKey = [
    props.routeName,
    params,
    props.routeParams,
    props.extraFilters,
  ];

  const { data: tableData, isLoading } = useQuery({
    queryKey,
    queryFn: async () => {
      try {
        const { displayFlags, ...rest } = params;

        const filters = [...params.filters, ...(props.extraFilters || [])];
        const routeName = props.routeName;
        const routeParams = {
          ...rest,
          ...props.routeParams,
          order:
            rest.order_direction === "asc"
              ? rest.order_by
              : `-${rest.order_by}`,
          filters: filters.reduce(
            (acc, filter) => {
              // @ts-expect-error
              acc[filter.key] = {
                condition: filter.condition,
                values: filter.values,
              };
              return acc;
            },
            {} as Record<string, { condition: string; values: string[] }>,
          ),
          ...displayFlags,
        };

        const routeUrl = route(routeName, routeParams);

        setPreviousTableRouteObject({
          routeName,
          routeParams,
        });

        const response = await axios.get<PaginatedResource<TData>>(routeUrl);
        const indexedData = response.data.data.map((item: TODO) => {
          return {
            totalCount: response.data.meta.total,
            objectId: item.id as number,
            routeKey: item.route_key as string,
            currentPage: response.data.meta.current_page,
            perPage: response.data.meta.per_page,
          };
        });

        setPreviousTableData(indexedData);
        return response.data;
      } catch (error) {
        console.error(error);
      }
    },
    placeholderData: (prev) => prev,
    refetchInterval: props.refetchInterval,
  });

  const updateParams = (partialParams: Partial<typeof params>) => {
    const newParams = {
      ...params,
      ...partialParams,
    };
    setParams(newParams);
  };

  const { mutateAsync } = useMutation({
    mutationFn: async (data: TData[]) => props.dndSort?.onChange(data),
    onMutate: async (newData: TData[]) => {
      // Cancel any outgoing refetch
      // (so they don't overwrite our optimistic update)

      await queryClient.cancelQueries({ queryKey });

      // Snapshot the previous value
      const prevData = queryClient.getQueryData(queryKey);

      // Optimistically update to the new value
      queryClient.setQueryData(
        queryKey,
        (oldData: PaginatedResource<TData>) => ({
          ...oldData,
          data: newData,
        }),
      );

      // Return a context object with the snapshot value
      return { prevData };
    },
    onError: (_err, _newTodo, context) => {
      toast.error("Unable to update sequence. Your changes have been reverted");
      queryClient.setQueryData(queryKey, context?.prevData);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  if (isLoading || !tableData) {
    return <LoadingSpinner />;
  }

  return (
    <TableProvider
      tableData={tableData}
      updateParams={updateParams as unknown as (params: unknown) => void}
    >
      <SimpleDataTable
        {...props}
        tableData={tableData}
        dndSort={
          props.dndSort
            ? {
                ...props.dndSort,
                onChange: mutateAsync,
              }
            : undefined
        }
      />
    </TableProvider>
  );
};
