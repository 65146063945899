import { useRef } from "react";
import { useDebouncedCallback } from "use-debounce";

import { usePageData } from "@/hooks/usePageData";

import { Input } from "../ui/input";
import { useTableConfig } from "./table-config-store";

export const SearchInput = () => {
  const searchInputRef = useRef<HTMLInputElement>(null);
  const {
    platform: { translations },
  } = usePageData();
  const search = useTableConfig((state) => state.search);
  const setSearch = useTableConfig((state) => state.setSearch);
  const debouncedHandleSearch = useDebouncedCallback(setSearch, 500);
  return (
    <div className="inline-flex">
      <Input
        kbd={"/"}
        className="h-8"
        data-internal-input={"table-search"}
        type="search"
        placeholder={translations.search}
        defaultValue={search}
        ref={searchInputRef}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === "Escape") {
            searchInputRef.current?.blur();
            e.preventDefault();
            e.stopPropagation();
            return;
          }
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          debouncedHandleSearch(e.target.value);
        }}
      />
    </div>
  );
};
