import * as HoverCardPrimitive from "@radix-ui/react-hover-card";
import React, { memo, useMemo } from "react";

import { Button } from "@/components/ui";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import { cn, timeFormats } from "@/lib/utils";
import { useTimeCardStore } from "@/stores/time-card-store";

export const TimeCard = memo(
  ({
    date,
    align = "end",
    side = "top",
    pastDateInRed = false,
    formatString,
  }: {
    date: string | null;
    align?: React.ComponentPropsWithoutRef<
      typeof HoverCardPrimitive.Content
    >["align"];
    side?: React.ComponentPropsWithoutRef<
      typeof HoverCardPrimitive.Content
    >["side"];
    onlyTime?: boolean;
    pastDateInRed?: boolean;
    formatString: string;
  }) => {
    const [relativeFormat, localFormat, localFormatNoIdentifier] = useMemo(
      () => timeFormats({ date, formatString }),
      [date, formatString],
    );
    const formats = ["Relative", "Local"];
    const formatValues = [relativeFormat, localFormat];
    const displayFormatValues = [relativeFormat, localFormatNoIdentifier];

    // Access Zustand store
    const { activeFormatIndex, setActiveFormatIndex } = useTimeCardStore();

    const handleChangeFormat = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();
      setActiveFormatIndex((prevIndex) => (prevIndex + 1) % formats.length);
    };

    if (!date) {
      return "-";
    }

    const dateInPast = new Date(date) < new Date();

    return (
      <div className={"group inline-flex flex-1"}>
        <HoverCard openDelay={0} closeDelay={75}>
          <HoverCardTrigger
            className={cn(
              "cursor-default border-b-2 border-dotted border-foreground/20 font-mono text-xs group-hover:border-foreground/40",
              dateInPast && pastDateInRed && "text-red-500",
            )}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {displayFormatValues[activeFormatIndex]
              .replace("about ", "")
              .replace("less than a minute ago", "just now")
              .replace("over ", "")
              .replace("almost ", "")}
          </HoverCardTrigger>
          <HoverCardContent
            align={align}
            side={side}
            className={"w-72 space-y-1"}
          >
            {formats.map((label, index) => (
              <DistanceToNowRow
                key={label}
                value={formatValues[index]}
                label={label}
                active={index === activeFormatIndex}
              />
            ))}
            <div className={"pt-2"}>
              <Button
                className={"w-full"}
                variant={"secondary"}
                onClick={handleChangeFormat}
              >
                Change
              </Button>
            </div>
          </HoverCardContent>
        </HoverCard>
      </div>
    );
  },
  (prevProps, nextProps) =>
    prevProps.date === nextProps.date &&
    prevProps.formatString === nextProps.formatString &&
    prevProps.align === nextProps.align &&
    prevProps.pastDateInRed === nextProps.pastDateInRed,
);
TimeCard.displayName = "TimeCard";

const DistanceToNowRow = (props: {
  label: string;
  value: string;
  active?: boolean;
}) => {
  return (
    <div className="flex items-baseline justify-between text-xs">
      <span
        className={props.active ? "text-foreground" : "text-muted-foreground"}
      >
        {props.label}
      </span>
      <div
        className={cn(
          "mx-1 flex-1 border-b-2 border-dotted",
          props.active && "border-foreground",
        )}
      />
      <span
        className={cn(
          "font-medium",
          props.active ? "text-foreground" : "text-muted-foreground",
        )}
      >
        {props.value}
      </span>
    </div>
  );
};
