import { HTMLAttributes, forwardRef } from "react";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui";
import { cn } from "@/lib/utils";
import { User } from "@/types/user-types";

interface UserAvatarProps extends HTMLAttributes<HTMLDivElement> {
  user: User;
}

export const UserAvatar = forwardRef<HTMLDivElement, UserAvatarProps>(
  ({ user, className, ...props }, ref) => {
    return (
      <Avatar
        ref={ref}
        className={cn(
          "size-8 cursor-pointer text-gray-200 hover:border hover:border-white hover:text-white",
          className,
        )}
        {...props}
      >
        <AvatarImage
          src={user.avatar}
          alt={`Profile picture of ${user.full_name}`}
        />
        <AvatarFallback className="bg-gray-900 text-sm">
          {user.initials}
        </AvatarFallback>
      </Avatar>
    );
  },
);

UserAvatar.displayName = "UserAvatar";
