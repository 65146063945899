import {
  ColumnDef,
  Row,
  RowSelectionState,
  Table,
} from "@tanstack/react-table";
import { ReactNode, useEffect, useMemo, useRef, useState } from "react";

import { cn } from "@/lib/utils";

import {
  Checkbox,
  IconButton,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../ui";

export type DataTableRowSelectionProps<TData> =
  | {
      enabled: boolean;
      isRowEnabled: (row: TData) => boolean;
      tooltip?: string | ((row: TData) => string);
      prefill?: string[];
      bulkActions: (
        rowSelectionState: RowSelectionState,
        onDone: () => void,
      ) => ReactNode;
    }
  | undefined;

export const useRowSelectionColumn = <TData,>(
  props: DataTableRowSelectionProps<TData> = {
    enabled: false,
    isRowEnabled: () => true,
    tooltip: undefined,
    prefill: undefined,
    bulkActions: () => null,
  },
) => {
  const isBulkActionsEnabled = true;
  const selectionColumn: ColumnDef<TData> | null = useMemo(() => {
    if (!props.enabled || !isBulkActionsEnabled) {
      return null;
    }
    return {
      id: "select",
      size: 34,
      minSize: 34,
      maxSize: 34,
      enableResizing: false,
      header: ({ table }: { table: Table<TData> }) => (
        <div
          className={cn(
            "pr-2",
            table.getIsAllPageRowsSelected() ||
              table.getIsSomePageRowsSelected()
              ? ""
              : "opacity-50 transition-opacity duration-100 hover:opacity-100",
          )}
        >
          <Checkbox
            checked={
              table.getIsAllPageRowsSelected()
                ? true
                : table.getIsSomePageRowsSelected()
                  ? "indeterminate"
                  : false
            }
            onCheckedChange={() => {
              if (table.getIsSomePageRowsSelected()) {
                table.toggleAllPageRowsSelected(false);
                return;
              }
              table.getRowModel().rows.forEach((row) => {
                if (props.isRowEnabled(row.original)) {
                  row.toggleSelected();
                }
              });
            }}
            aria-label="Select all"
            className={cn(
              "flex items-center justify-center",
              "relative after:absolute after:inset-[-100%] after:size-[300%] after:content-['']",
            )}
          />
        </div>
      ),
      cell: ({ row }: { row: Row<TData> }) => {
        const tooltip =
          typeof props.tooltip === "function"
            ? props.tooltip(row.original)
            : props.tooltip;
        const checked = row.getIsSelected();
        const enabled = props.isRowEnabled(row.original);
        return (
          <Tooltip>
            <TooltipTrigger asChild>
              <div
                className={cn(
                  "group-hover/row:visible",
                  checked ? "visible" : "invisible",
                )}
              >
                <div
                  className={cn(
                    checked || !enabled
                      ? ""
                      : "opacity-50 transition-opacity duration-100 hover:opacity-100",
                  )}
                >
                  <Checkbox
                    checked={checked}
                    disabled={!enabled}
                    onCheckedChange={(checked) => {
                      row.toggleSelected(!!checked);
                    }}
                    aria-label="Select row"
                    className={cn(
                      "flex items-center justify-center",
                      "relative after:absolute after:inset-[-100%] after:size-[300%] after:content-['']",
                    )}
                  />
                </div>
              </div>
            </TooltipTrigger>
            {tooltip && <TooltipContent side="right">{tooltip}</TooltipContent>}
          </Tooltip>
        );
      },
    };
  }, [isBulkActionsEnabled, props]);

  return {
    selectionColumn,
  };
};

export const useRowSelection = <TData,>(
  selection: DataTableRowSelectionProps<TData>,
  table: Table<TData>,
) => {
  const [isSelectionMode, setIsSelectionMode] = useState(false);
  const selectionTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);

  const hasSelection = Object.keys(table.getState().rowSelection).length > 0;
  useEffect(() => {
    const toggleSelectionMode = () => {
      if (hasSelection) {
        if (selectionTimeoutRef.current) {
          clearTimeout(selectionTimeoutRef.current);
        }
        setIsSelectionMode(true);
      } else {
        selectionTimeoutRef.current = setTimeout(() => {
          setIsSelectionMode(false);
        }, 1000);
        return () => clearTimeout(selectionTimeoutRef.current);
      }
    };
    toggleSelectionMode();
  }, [hasSelection]);

  useEffect(() => {
    if (
      selection?.enabled &&
      selection?.prefill &&
      selection.prefill.length > 0 &&
      Object.keys(table.getState().rowSelection).length === 0
    ) {
      table.setRowSelection(
        selection.prefill.reduce((p, c) => ({ ...p, [c]: true }), {}),
      );
    }
  }, [table, selection?.enabled, selection?.prefill]);

  return {
    isSelectionMode,
  };
};

type TableSelectionToolbarProps<TData> = {
  selection: DataTableRowSelectionProps<TData>;
  selectionState: RowSelectionState;
  setSelectionState: (state: RowSelectionState) => void;
};

export const TableSelectionToolbar = <TData,>(
  props: TableSelectionToolbarProps<TData>,
) => {
  const { setSelectionState } = props;

  useEffect(() => {
    if (props.selection?.enabled) {
      const onKeyPress = (e: KeyboardEvent) => {
        if (e.key === "Escape") {
          setSelectionState({});
        }
      };
      window.addEventListener("keydown", onKeyPress);
      return () => {
        window.removeEventListener("keydown", onKeyPress);
      };
    }
  }, [setSelectionState, props.selection?.enabled]);

  const selectedCount = Object.keys(props.selectionState).length;

  return (
    <div
      className={
        cn()
        // "absolute top-2 left-0 right-0 flex justify-center",
        // The below lines enable animation from the top
        // (props.selection?.prefill && props.selection.prefill.length > 0) ||
        //   selectedCount > 0
        //   ? "top-2 animate-in slide-in-from-top-40 duration-500 ease-in-out"
        //   : "-top-40 animate-out slide-out-to-top-40 duration-500 ease-in-out",
      }
    >
      <div className="flex items-center space-x-2">
        {/* <div className="flex items-center space-x-2 rounded-lg border bg-background p-2 shadow-md shadow-black/20 transition-shadow duration-300"> */}
        {selectedCount > 0 && (
          <div className="flex h-8 flex-row items-center space-x-1 divide-x rounded-lg border border-dashed border-foreground/30 pl-2">
            <span className="text-sm">{selectedCount} selected</span>
            <span>
              <IconButton
                variant="tertiary"
                size="icon"
                icon="fa-xmark"
                onClick={() => {
                  setSelectionState({});
                }}
                className="h-7 w-7"
              />
            </span>
          </div>
        )}
        {props.selection?.bulkActions(props.selectionState, () => {
          props.setSelectionState({});
        })}
      </div>
    </div>
  );
};
