import React, { Fragment } from "react";

import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui";
import { getQueryParams } from "@/lib/utils";
import { LinkType } from "@/types";

export type DataTablePaginationProps = {
  from: number;
  to: number;
  total: number;
  last_page: number;
  links: LinkType[];
  onLinkClick?: (link: LinkType) => void;
};

const DataTablePaginationLink = ({
  link,
  onLinkClick,
}: {
  link: LinkType;
  onLinkClick?: DataTablePaginationProps["onLinkClick"];
}) => {
  const params = getQueryParams();
  const { page, ...others } = params;
  const otherParams = new URLSearchParams(
    others as Record<string, string>,
  ).toString();
  const paginationUrl = link.url;
  const linkURL = `${paginationUrl}&${otherParams}`;
  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!onLinkClick) {
      return;
    }
    e.preventDefault();
    onLinkClick(link);
  };
  switch (link.label) {
    case "previous":
      return (
        <PaginationPrevious
          href={linkURL}
          disabled={!paginationUrl}
          onClick={onClick}
        />
      );

    case "next":
      return (
        <PaginationNext
          href={linkURL}
          disabled={!paginationUrl}
          onClick={onClick}
        />
      );

    case "...":
      return <PaginationEllipsis />;

    default:
      return (
        <PaginationLink href={linkURL} isActive={link.active} onClick={onClick}>
          {link.label}
        </PaginationLink>
      );
  }
};

const DataTablePagination = ({
  links,
  from,
  to,
  total,
  last_page,
  onLinkClick,
}: DataTablePaginationProps) => {
  return (
    <div className="flex flex-col items-center justify-between border-t border-pagination-container-default-border bg-pagination-container-default-bg px-4 py-3 sm:px-6">
      <div className="flex w-full flex-col sm:flex-row sm:items-center sm:justify-between">
        <div className="flex flex-col items-center pb-2 text-center sm:pb-0 sm:text-left">
          {total > 0 ? (
            <p className="text-sm text-body-text-default">
              Showing <span className="font-medium">{from}</span> to{" "}
              <span className="font-medium">{to}</span> of{" "}
              <span className="font-medium">{total.toLocaleString()}</span>{" "}
              results
            </p>
          ) : (
            <p className="text-sm text-body-text-default">No results</p>
          )}
        </div>
        <div>
          {last_page > 1 && (
            <Pagination>
              <PaginationContent>
                {links.map((link) => (
                  <Fragment key={`${link.url}-${link.label}`}>
                    <DataTablePaginationLink
                      link={link}
                      onLinkClick={onLinkClick}
                    />
                  </Fragment>
                ))}
              </PaginationContent>
            </Pagination>
          )}
        </div>
      </div>
    </div>
  );
};

export { DataTablePagination };
