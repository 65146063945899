import { usePage } from "@inertiajs/react";

import { AssetType, Feature } from "@/types";
import { TBreadcrumb } from "@/types/breadcrumb-types";
import { AuthenticatedUser } from "@/types/user-types";

export type NavItem = {
  id: string;
  label: string;
  type: "workspace" | "link" | "header" | "divider" | "logout" | "slideout";
  component: string | null;
  class: string | null;
  icon: string | null;
  link: string | null;
  new_window: boolean;
  visible: boolean;
  submenu: NavItem[];
  badge?: number;
};

const translationKeys = [
  "audit",
  "asset",
  "note",
  "safetypage",
  "no_audit_conducted",
  "risk_not_available",
  "organisation",
  "site",
  "building",
  "level",
  "record",
  "create_asset",
  "create_record",
  "filter",
  "display",
  "search",
  "order_by",
  "ordering",
  "per_page",
  "none",
  "select",
] as const;

type Translations = {
  [k in (typeof translationKeys)[number]]: string;
};

export type PageData = {
  breadcrumbs: TBreadcrumb[];
  showGlobalBreadcrumbs?: boolean;
  environment: string;
  errors: Record<string, unknown>;
  navItems: {
    mainNavigation: NavItem[];
    settingsDropdown: NavItem[];
  };
  company: {
    companyName: string;
    companySubdomain: string;
    companyUuid: string;
  };
  auth: {
    companies: Company[];
    user: AuthenticatedUser;
    session_expires_at: string;
    session_lifetime: number;
  };
  platform: {
    version: string;
    translations: Translations;
    legacy_primary_asset_level: AssetType["name"];
    asset_types: platformAssetTypes;
  };
  features: Feature[];
  module_settings: {
    confirmed_in_register: "0" | "1" | "2";
    register_versioning: "0" | "1";
  };
};

export type platformAssetTypes = {
  [key: string]: {
    name: string;
    uuid: string;
    settings: {
      can_create_records: number[];
    } | null;
  };
};

type Company = {
  uuid: string;
  companyname: string;
  shortname: string;
  domain: string;
  version: string;
};

export const usePageData = <T>() => {
  return usePage().props as PageData & T;
};
