import { router } from "@inertiajs/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { usePostHog } from "posthog-js/react";
import { useFeatureFlagEnabled } from "posthog-js/react";
import React, { useEffect } from "react";
import { FieldValues, useForm } from "react-hook-form";

import { AsideMenu } from "@/components/AsideMenu";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Icon } from "@/components/Icon";
import { ThemeToggle } from "@/components/ThemeToggle";
import {
  Button,
  Dialog,
  DialogContent,
  Form,
  FormField,
  FormItem,
  FormMessage,
  InputIconLeft,
} from "@/components/ui";
import { Toaster } from "@/components/ui/sonner";
import { useLockScreen } from "@/hooks/useLockScreen";
import { PageData, usePageData } from "@/hooks/usePageData";
import { config, defaultErrorMessage } from "@/lib/constants";
import { useAppContext } from "@/providers/AppProvider";
import { useBreadcrumbContext } from "@/providers/BreadcrumbProvider";
import { LightboxProvider } from "@/providers/LightboxProvider";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: import.meta.env.PROD ? 3 : false,
    },
  },
});

export default function AuthenticatedLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const { desktopSidebarOpen, mobileSidebarOpen, toggleMobileSidebar } =
    useAppContext();

  const { auth, environment, showGlobalBreadcrumbs, breadcrumbs, company } =
    usePageData();
  const { setBreadcrumbState } = useBreadcrumbContext();

  const canToggleThemeFlag = useFeatureFlagEnabled("can_toggle_theme");

  const {
    lockScreen,
    setLockScreen,
    notifyLockScreenUnlocked,
    notifyLockScreenLogout,
  } = useLockScreen(auth);
  const posthog = usePostHog();

  useEffect(() => {
    if (breadcrumbs.length === 0) return;
    setBreadcrumbState(breadcrumbs);
  }, [breadcrumbs, setBreadcrumbState]);

  useEffect(() => {
    if (auth.user.email) {
      window.Intercom("update", {
        app_id: config.INTERCOM_APP_ID,
        email: auth.user.email,
        name: auth.user.full_name,
        user_hash: auth.user.intercom_hash,
        user_id: auth.user.intercom_uuid,
      });
      posthog?.identify(auth.user.posthog_id, {
        email: auth.user.email,
        name: auth.user.full_name,
        environment: environment,
      });
      posthog.group("company", company.companySubdomain, {
        name: company.companyName,
      });
      posthog.capture("user_authenticated");
    }
    if (!auth.user.email) {
      posthog?.reset();
    }
  }, [
    auth.user.email,
    auth.user.full_name,
    auth.user.initials,
    auth.user.intercom_uuid,
    auth.user.uuid,
    environment,
    posthog,
    company.companyName,
    company.companySubdomain,
  ]);

  return (
    <QueryClientProvider client={queryClient}>
      <div
        onClick={toggleMobileSidebar}
        className={`absolute inset-0 z-40 h-full w-full bg-black/20 ${
          !mobileSidebarOpen ? "hidden" : ""
        }`}
      ></div>
      <div
        id="page-container"
        className={`mx-auto flex w-full flex-col ${
          desktopSidebarOpen ? "lg:pl-[250px]" : "lg:pl-16"
        }`}
      >
        <AsideMenu />
        <main
          id="page-content"
          className={`flex min-h-screen flex-col bg-[var(---surface-default)]`}
        >
          {showGlobalBreadcrumbs && (
            <Breadcrumbs>{canToggleThemeFlag && <ThemeToggle />}</Breadcrumbs>
          )}
          {children}
        </main>
        <Toaster />
        <LightboxProvider />
      </div>
      <LockScreenDialog
        open={lockScreen}
        setOpen={setLockScreen}
        user={auth.user}
        onLogin={() => {
          notifyLockScreenUnlocked();
          setLockScreen(false);
          router.reload();
        }}
        onLogout={() => {
          notifyLockScreenLogout();
          setLockScreen(false);
          router.reload();
        }}
      />
    </QueryClientProvider>
  );
}

const LockScreenDialog = (props: {
  open: boolean;
  setOpen: (open: boolean) => void;
  user: PageData["auth"]["user"];
  onLogin: () => void;
  onLogout: () => void;
}) => {
  return (
    <Dialog open={props.open} onOpenChange={props.setOpen}>
      <DialogContent
        className="px-4 py-6"
        onEscapeKeyDown={(event) => event.preventDefault()}
        onPointerDownOutside={(event) => event.preventDefault()}
        onInteractOutside={(event) => event.preventDefault()}
        closeButton={false}
        dialogOverlayClassName={"bg-gray-900/80 backdrop-blur-sm"}
      >
        <div
          className={
            "flex max-w-96 items-center gap-x-2 rounded-lg border border-orange-300 bg-orange-200 p-2 text-orange-700"
          }
        >
          <Icon icon={"fa-hourglass-end"} className={""} />
          <span>
            {props.user.first_name}, your session was automatically locked due
            to in-activity. Please enter your password to continue.
          </span>
        </div>
        <LogBackForm email={props.user.email} onDone={props.onLogin} />
        <Button
          variant={"tertiary"}
          className={"text-body-color-accent-default"}
          onClick={props.onLogout}
        >
          Log out
        </Button>
      </DialogContent>
    </Dialog>
  );
};

const LogBackForm = (props: { email: string; onDone: () => void }) => {
  const form = useForm();

  const logBack = async (values: FieldValues) => {
    try {
      await axios.post(
        route("login"),
        {
          ...values,
          email: props.email,
        },
        { headers: { Accept: "application/json" } },
      );
      props.onDone();
    } catch (error) {
      form.setError("password", {
        type: "manual",
        message:
          (error instanceof AxiosError && error?.response?.data.message) ||
          defaultErrorMessage,
      });
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(logBack)}>
        <FormField
          control={form.control}
          name="password"
          rules={{ required: "Password is required" }}
          render={({ field }) => {
            return (
              <FormItem>
                <InputIconLeft
                  icon={"fa-key"}
                  placeholder={"Password"}
                  type={"password"}
                  {...field}
                />
                <FormMessage />
              </FormItem>
            );
          }}
        />

        <div className={"mt-4"}>
          {!form.formState.isSubmitting ? (
            <Button className="w-full" size="lg" type="submit">
              Sign in
            </Button>
          ) : (
            <Button className="w-full" size="lg" type="button" disabled>
              Please wait...
            </Button>
          )}
        </div>
      </form>
    </Form>
  );
};
