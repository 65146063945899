import { useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";

import { axios } from "@/axios";
import { DynamicForm, DynamicFormFieldSet } from "@/components/DynamicForm";
import { useFormQuery } from "@/hooks/useFormQuery";
import { transformValuesToDynamicFormMarkup } from "@/lib/utils";
import { Resource } from "@/types";
import { Member } from "@/types/user-types";

import { LoadingSpinner } from "./LoadingSpinner";
import { LocalFile } from "./UploadInput";
import { Sheet, SheetContent, SheetHeader, SheetTitle } from "./ui";

export const MemberForm = (props: {
  memberRouteKey: Member["route_key"];
  isProfileUpdate?: boolean;
  onDone?: () => void;
}) => {
  const queryClient = useQueryClient();

  const { data: memberFormMarkup } = useFormQuery({
    formName: "MEMBER_FORM",
    params: { memberId: props.memberRouteKey },
  });

  const memberRouteKey =
    "memberRouteKey" in props ? props.memberRouteKey : undefined;

  const { data: initialValues } = useQuery({
    queryKey: ["member-details", memberRouteKey, memberFormMarkup],
    queryFn: async () => {
      const memberResponse = await axios.get<Resource<Member>>(
        route("api.settings.members.show", { member: memberRouteKey }),
      );
      return transformValuesToDynamicFormMarkup(
        memberFormMarkup as DynamicFormFieldSet[],
        memberResponse.data.data,
      ) as Member;
    },
  });

  const onSubmit = async (values: Member) => {
    const contractor = values.contractor;
    if (contractor) {
      const signature = contractor.signature as LocalFile | string | null;

      if (
        signature &&
        !(typeof signature === "string") &&
        signature.status === "UPLOADING"
      ) {
        toast.error("Signature upload not completed yet");

        return;
      }

      contractor.signature = signature
        ? typeof signature === "string"
          ? signature
          : signature.url
        : null;
    }

    const avatar = values.avatar as LocalFile | string | null;

    if (
      avatar &&
      !(typeof avatar === "string") &&
      avatar.status === "UPLOADING"
    ) {
      toast.error("Avatar upload not completed yet");
      return;
    }

    values.avatar = avatar
      ? typeof avatar === "string"
        ? avatar
        : avatar.url
      : undefined;

    const response = await axios
      .put<
        Resource<Member>
      >(route("api.settings.members.update", { member: memberRouteKey }), values)
      .then((res) => res.data.data);

    queryClient.setQueryData(["profile"], () => response);
    toast.success(
      `${props.isProfileUpdate ? "Profile" : "Member"} updated successfully`,
    );
    props.onDone?.();
  };

  if (
    memberFormMarkup === undefined ||
    (memberRouteKey && initialValues === undefined)
  ) {
    return <LoadingSpinner />;
  }

  return (
    <DynamicForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      formInfo={memberFormMarkup}
    />
  );
};

export const MemberSheet = (props: {
  memberRouteKey: Member["route_key"];
  isProfileUpdate?: boolean;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onDone: () => void;
}) => {
  return (
    <>
      <Sheet open={props.open} onOpenChange={props.onOpenChange}>
        <SheetContent className="sm:w-1/3">
          <SheetHeader>
            <SheetTitle>Edit user</SheetTitle>
          </SheetHeader>
          <MemberForm
            memberRouteKey={props.memberRouteKey}
            isProfileUpdate={props.isProfileUpdate}
            onDone={props.onDone}
          />
        </SheetContent>
      </Sheet>
    </>
  );
};
