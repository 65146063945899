import FileDownloadCell from "@/components/table-cells/FileDownloadCell";
import { getPath } from "@/lib/utils";
import { DocumentList } from "@/types";

import { RenderCellProps } from "./cell-types";

export const FileDownloadCellComponent = <T extends object>({
  row,
  accessorKey,
  cellInfo,
}: RenderCellProps<T>) => {
  if (cellInfo.type !== "FILE_DOWNLOAD") {
    return null;
  }
  const listItems = getPath(row.original, accessorKey) as DocumentList[];
  return <FileDownloadCell listItems={listItems} />;
};
