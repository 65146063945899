import { ColumnDef } from "@tanstack/react-table";

import { RenderCell } from "@/components/data-table/cells/render-cell";
import { PaginatedResourceColumn } from "@/types";

export const generateColumnDefs = <T extends object>(
  data: PaginatedResourceColumn[],
): ColumnDef<T>[] => {
  return data.map(({ header, accessorKey, cellInfo, sortable }) => {
    return {
      header,
      enableSorting: !!sortable,
      cell: ({ row }) => (
        <RenderCell cellInfo={cellInfo} accessorKey={accessorKey} row={row} />
      ),
      accessorFn: () => null,
    };
  });
};
