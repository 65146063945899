import { Head } from "@inertiajs/react";
import React from "react";

export default function Layout({ children }: { children: React.ReactNode }) {
  return (
    <>
      <Head title={"Forgot password"} />
      <div className="absolute inset-0 mx-auto flex h-screen w-full flex-col">
        <div className="flex max-w-full flex-auto flex-col md:w-5/12">
          <div className="absolute z-20 flex items-center p-8 text-lg font-medium">
            <img
              className="text-white"
              src="/media/octfolio-als-white.svg"
              width="200em"
              alt="Octfolio logo"
            />
          </div>
          <div className="absolute inset-0 hidden bg-[url(/media/auth-bg.webp)] bg-cover md:flex md:w-5/12" />
          <div className="absolute inset-0 hidden flex-col bg-black opacity-60 md:flex md:w-5/12" />
        </div>
        <div className="ml-auto flex h-full w-full grow bg-[var(---surface-default)] shadow-xl md:w-7/12">
          <div className="flex w-full flex-col p-8">
            <div className="flex justify-end space-x-2"></div>
            <div className="flex grow items-center">
              <div className="mx-auto w-full max-w-md">{children}</div>
            </div>
            <div className="bottom-0 flex h-8 items-center text-xs text-body-text-x-subtle">
              <span>
                Photo by{" "}
                <a
                  className="underline"
                  href="https://unsplash.com/@timothy_swope?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
                  target="_blank"
                >
                  Timothy Swope
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
