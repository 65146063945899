import { useEffect, useMemo } from "react";
import {
  ControllerRenderProps,
  FieldValues,
  useController,
  useFormContext,
  useWatch,
} from "react-hook-form";

import { FormScoreRating, ReportOption, TODO } from "@/types";

import { DynamicFormField } from "./DynamicForm";
import { RiskBadge, RiskBadgeProps } from "./RiskBadge";

export type ScoreInput = {
  input: "SCORE_INPUT";
  scoreDependsOn: number[];
  minScoreFormQuestionIds: number[];
  scoreRatings: FormScoreRating[];
};

export const FormScoreInput = (
  props: Omit<DynamicFormField, "type"> & {
    type: ScoreInput;
  },
) => {
  const { field } = useController({ name: props.name });
  useScore(
    field,
    props.type.scoreDependsOn,
    props.type.minScoreFormQuestionIds,
  );
  const ratingFilterId = field.name === "24" ? "0" : field.name;
  const scoreRating = props.type.scoreRatings.find(
    (rating) =>
      String(rating.score_id) === field.value &&
      String(rating.form_question_id) === ratingFilterId,
  );
  const label = scoreRating?.rating || "";
  return (
    <RiskBadge
      label={label}
      score={Number(field.value)}
      color={scoreRating?.badge_colour as RiskBadgeProps["color"]}
      size={"sm"}
    />
  );
};
FormScoreInput.displayName = "FormScoreInput";

const getFieldScore = (fieldValue: TODO) => {
  let score = 0;
  if (fieldValue) {
    if (typeof fieldValue === "string" && !isNaN(Number(fieldValue))) {
      score = Number(fieldValue);
    } else if (typeof fieldValue === "object") {
      const fieldObject = fieldValue;
      if ("result_score" in fieldObject) {
        const sample = fieldValue;
        if (!sample.archived && sample.result_score) {
          score += Number(sample.result_score);
        }
      } else {
        const value = fieldValue;
        if (value.score && !isNaN(Number(value.score))) {
          score = Number(value.score);
        }
      }
    }
  }
  return score;
};

const useScore = (
  field: ControllerRenderProps<FieldValues, string>,
  scoreDependsOn: number[],
  minScoreFormQuestionIds: number[],
) => {
  const { value, onChange, name: fieldName } = field;
  const { control } = useFormContext();
  const hasScore = scoreDependsOn.length;
  const hasMinScore = minScoreFormQuestionIds.length;

  // Filter out the current field from the fields to watch
  const fieldsToWatch = useMemo(
    () => scoreDependsOn.map((s) => String(s)).filter((f) => f !== fieldName),
    [scoreDependsOn, fieldName],
  );

  const minScoreFieldsToWatch = useMemo(
    () =>
      minScoreFormQuestionIds
        .map((s) => String(s))
        .filter((f) => f !== fieldName),
    [minScoreFormQuestionIds, fieldName],
  );

  // Use useWatch with control explicitly provided
  const values = useWatch({
    name: fieldsToWatch,
    control,
  });

  const minScoreValues = useWatch({
    name: minScoreFieldsToWatch,
    control,
  }) as unknown as ReportOption[];

  // Calculate score based on watched values
  const nextScore = useMemo(() => {
    let score = 0;
    const hasNegative = values.find((v) => getFieldScore(v) < 0);

    if (hasNegative) {
      score = -1;
    } else {
      for (let i = 0; i < values.length; i += 1) {
        score += getFieldScore(values[i]);
      }
      score = Math.round(score);
    }

    // Find the maximum min_score from the watched values
    let maxMinScore = 0;
    if (hasMinScore && Array.isArray(minScoreValues)) {
      minScoreValues.forEach((option) => {
        if (option && option.min_score) {
          const optionMinScore = parseInt(String(option.min_score));
          if (!isNaN(optionMinScore) && optionMinScore > maxMinScore) {
            maxMinScore = optionMinScore;
          }
        }
      });
    }

    // Apply max min_score if calculated score is below it
    if (maxMinScore > 0 && score < maxMinScore) {
      score = maxMinScore;
    }

    return String(score);
  }, [hasMinScore, minScoreValues, values]);

  useEffect(() => {
    if (hasScore && value !== nextScore) {
      console.log(
        `Updating score for ${fieldName} from ${value} to ${nextScore}`,
      );

      const handler = setTimeout(() => {
        onChange(nextScore);
      }, 500);

      return () => clearTimeout(handler);
    }
  }, [fieldName, hasScore, onChange, nextScore, value]);
};
