import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import * as React from "react";
import { CSSProperties } from "react";

import { Icon } from "@/components/Icon";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";

const Table = React.forwardRef<
  HTMLTableElement,
  React.HTMLAttributes<HTMLTableElement>
>(({ className, ...props }, ref) => (
  <div className="relative w-full flex-1 overflow-auto border-t">
    <table
      ref={ref}
      className={cn("w-full caption-bottom text-sm", className)}
      {...props}
    />
  </div>
));
Table.displayName = "Table";

const TableHeader = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <thead
    ref={ref}
    className={cn(
      "sticky top-0 z-10 bg-table-header-default-bg text-table-header-default-text shadow",
      className,
    )}
    {...props}
  />
));
TableHeader.displayName = "TableHeader";

const TableBody = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tbody
    ref={ref}
    className={cn(
      "bg-table-body-default-bg text-table-body-default-text",
      className,
    )}
    {...props}
  />
));
TableBody.displayName = "TableBody";

const TableFooter = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tfoot ref={ref} className={className} {...props} />
));
TableFooter.displayName = "TableFooter";

const TableRow = React.forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement>
>(({ className, ...props }, ref) => {
  return (
    <tr
      ref={ref}
      className={cn(
        "bg-table-body-default-bg",
        "hover:bg-table-body-hover-bg",
        className,
      )}
      {...props}
    />
  );
});
TableRow.displayName = "TableRow";

export const SortableTableRow = (
  props: React.HTMLAttributes<HTMLTableRowElement> & { rowKey: string },
) => {
  const { rowKey, ...otherProps } = props;
  const { transform, transition, setNodeRef, isDragging } = useSortable({
    id: props.rowKey,
    transition: null,
  });

  const _transform =
    isDragging && transform ? { ...transform, scaleY: 1 } : transform;

  const style: CSSProperties = {
    transform: CSS.Transform.toString(_transform),
    transition: transition,
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? 1 : 0,
  };

  if (_transform) {
    style.position = "relative";
  }

  return <TableRow {...otherProps} ref={setNodeRef} style={style} />;
};
SortableTableRow.displayName = "SortableTableRow";

export const RowDragHandleCell = ({
  rowId,
  disabled = false,
  disabledMessage,
}: {
  rowId: string;
  disabled?: boolean;
  disabledMessage?: string;
}) => {
  const { attributes, listeners, setNodeRef } = useSortable({
    id: rowId,
  });
  const button = (
    <button
      ref={setNodeRef}
      key={rowId}
      {...attributes}
      {...(disabled ? {} : listeners)}
      className="relative after:absolute after:inset-[-12px] after:content-['']"
    >
      <Icon
        icon={"fa-solid fa-grip-vertical"}
        className="text-muted-foreground opacity-0 transition-opacity duration-75 group-hover/row:opacity-100"
      />
    </button>
  );

  if (!disabled) {
    return button;
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <div className={"cursor-none opacity-50"}>{button}</div>
      </TooltipTrigger>
      <TooltipContent>{disabledMessage}</TooltipContent>
    </Tooltip>
  );
};

const TableHeadRow = React.forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement>
>(({ className, ...props }, ref) => (
  <tr
    ref={ref}
    className={cn("bg-table-body-default-bg", className)}
    {...props}
  />
));
TableHeadRow.displayName = "TableHeadRow";

const TableHead = React.forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <th
    ref={ref}
    className={cn(
      "cursor-pointer hover:bg-table-header-hover-bg",
      "h-11 whitespace-nowrap px-2 text-left align-middle font-medium [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-[2px]",
      className,
    )}
    {...props}
  />
));
TableHead.displayName = "TableHead";

const TableCell = React.forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <td
    ref={ref}
    className={cn(
      "p-2 align-middle [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-[2px]",
      className,
    )}
    {...props}
  />
));
TableCell.displayName = "TableCell";

const TableCaption = React.forwardRef<
  HTMLTableCaptionElement,
  React.HTMLAttributes<HTMLTableCaptionElement>
>(({ className, ...props }, ref) => (
  <caption
    ref={ref}
    className={cn("mt-4 text-sm text-muted-foreground", className)}
    {...props}
  />
));
TableCaption.displayName = "TableCaption";

export {
  Table,
  TableHeader,
  TableHeadRow,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
  TableCell,
  TableCaption,
};
