import { useQuery } from "@tanstack/react-query";

import { axios } from "@/axios";
import { DynamicFormFieldSet } from "@/components/DynamicForm";

type FormName =
  | "AUDIT_FORM"
  | "ASSET_FORM"
  | "CREATE_RECORD_FORM"
  | "CRM_CONTACT_FORM"
  | "SUPPORT_URL_FORM"
  | "MEMBER_FORM";

export const useFormQuery = (props: {
  formName: FormName;
  params?: Record<string, unknown>;
}) => {
  //  This hook fetches the form markup from the API and returns the response
  //  as a react-query query object. The query key is the form name.
  return useQuery({
    queryKey: [props.formName, props.params],
    queryFn: async () => {
      try {
        const assetResponse = await axios.get<DynamicFormFieldSet[]>(
          route("api.form-markup", { form: props.formName, ...props.params }),
        );
        return assetResponse.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  });
};
