import { FileIcon, ImageIcon } from "lucide-react";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import { ControllerProps, FieldPath, FieldValues } from "react-hook-form";

import { Icon } from "@/components/Icon";
import { config } from "@/lib/constants";

import { Button } from "./ui/button";
import { FormControl, FormField, FormItem, FormMessage } from "./ui/form";

const FileUpload = (
  props: {
    files?: File[];
    onChange: (files: File[]) => void;
  } & Omit<DropzoneOptions, "onDrop">,
) => {
  const {
    files,
    onChange,
    maxFiles,
    maxSize: maxSizeInMB = config.MAX_MB_UPLOAD.DEFAULT,
    ...dropzoneOptions
  } = props;
  const maxSize = maxSizeInMB * 1024 * 1024;
  const onDrop = <T extends File>(acceptedFiles: T[]) => {
    const newFiles = acceptedFiles?.filter(
      (acceptedFile) =>
        !files ||
        files.length === 0 ||
        !files.find((file) => file.name === acceptedFile.name),
    );

    if (newFiles.length > 0) {
      onChange([...(files || []), ...newFiles]);
    }
  };

  const { getRootProps, getInputProps, open, fileRejections, isDragActive } =
    useDropzone({
      // Disable click and keydown behavior
      noClick: true,
      noKeyboard: true,
      onDrop,
      maxFiles,
      maxSize,
      disabled: (maxFiles && files && maxFiles === files?.length) || false,
      ...dropzoneOptions,
    });

  const isFileTooLarge = fileRejections?.[0]?.file.size > maxSize;

  return (
    <div>
      <div
        {...getRootProps({
          className: `flex flex-col items-center justify-center border-2 border-dashed rounded-lg p-8 ${isDragActive ? "border-primary bg-body-surface-light " : ""}`,
        })}
      >
        <input {...getInputProps()} />
        <div className="flex items-center space-x-4">
          <FileIcon className="h-8 w-8" />
          <ImageIcon className="h-8 w-8" />
        </div>
        <div className={"mt-2 text-xs text-muted-foreground"}>
          Max {maxSizeInMB}MB
        </div>
        <Button
          type="button"
          className="mt-4"
          variant="secondary"
          onClick={open}
        >
          Select
        </Button>
        {isFileTooLarge && (
          <div className="text-destructive mt-2 text-[0.8rem] font-medium">
            File is too large.
          </div>
        )}
      </div>
      <div className="mt-1 flex flex-col">
        {files?.map?.((file) => (
          <div key={file.name} className="mt-1">
            <div className="center relative inline-block select-none whitespace-nowrap rounded-lg border border-gray-950/40 bg-gray-100 px-3.5 py-2 align-baseline font-sans text-xs font-bold uppercase leading-none text-gray-950 hover:bg-gray-150">
              <div className="mr-5 mt-px">{file.name}</div>
              <div
                className="absolute right-1 top-1 mx-px mt-[0.5px] w-max rounded-md bg-red-500 text-white hover:cursor-pointer hover:bg-red-600 active:bg-red-700"
                onClick={() => {
                  onChange([...files.filter((f) => f.name !== file.name)]);
                }}
              >
                <Icon icon={"fa-close"} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const FormFileUploadInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  fileUploadProps,
  ...props
}: Omit<ControllerProps<TFieldValues, TName>, "render"> & {
  fileUploadProps: Omit<DropzoneOptions, "onDrop">;
}) => {
  return (
    <FormField
      {...props}
      render={({ field }) => {
        return (
          <FormItem>
            <FormControl>
              <FileUpload
                files={field.value}
                onChange={(files) => {
                  field.onChange(files);
                }}
                {...(fileUploadProps || {})}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        );
      }}
    />
  );
};

const ImageUpload = (
  props: {
    files?: File[];
    onChange: (files: File[]) => void;
    btnLabel?: string;
  } & Omit<DropzoneOptions, "onDrop">,
) => {
  const {
    files,
    onChange,
    maxFiles,
    btnLabel = "Select",
    maxSize: maxSizeInMB = config.MAX_MB_UPLOAD.IMAGE,
    ...dropzoneOptions
  } = props;

  const maxSize = maxSizeInMB * 1024 * 1024;

  const filePreview = files && URL.createObjectURL(files[0]);

  const onDrop = <T extends File>(acceptedFiles: T[]) => {
    const newFiles = acceptedFiles?.filter(
      (acceptedFile) =>
        !files ||
        files.length === 0 ||
        !files.find((file) => file.name === acceptedFile.name),
    );

    if (newFiles.length > 0) {
      onChange([...(files || []), ...newFiles]);
    }
  };

  const { getRootProps, getInputProps, open, fileRejections } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDrop,
    maxFiles,
    disabled: (maxFiles && files && maxFiles === files?.length) || false,
    accept: { "image/*": [] },
    maxSize: maxSize,
    ...dropzoneOptions,
  });

  const isFileTooLarge = fileRejections?.[0]?.file.size > maxSize;

  return (
    <div
      {...getRootProps({
        className:
          "h-full w-full flex flex-col border-2 border-dashed rounded-lg relative",
      })}
    >
      <input {...getInputProps()} />
      <div className="flex flex-1 flex-col items-center justify-center">
        {filePreview ? (
          <div className="absolute inset-0">
            <img src={filePreview} className="h-full w-full object-contain" />
          </div>
        ) : (
          <>
            <ImageIcon className="h-8 w-8" />
            <div className={"mt-2 text-xs text-muted-foreground"}>
              Max {maxSizeInMB}MB
            </div>
            <Button
              type="button"
              className="mt-4"
              variant="secondary"
              onClick={open}
            >
              {btnLabel}
            </Button>
            {isFileTooLarge && (
              <div className="text-destructive mt-2 text-[0.8rem] font-medium">
                File is too large.
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const FormFileImageInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  fileUploadProps,
  btnLabel,
  ...props
}: Omit<ControllerProps<TFieldValues, TName>, "render"> & {
  fileUploadProps: Omit<DropzoneOptions, "onDrop">;
  btnLabel?: string;
}) => {
  return (
    <FormField
      {...props}
      render={({ field }) => {
        return (
          <FormItem className="h-full">
            <FormControl>
              <ImageUpload
                files={field.value}
                onChange={(files) => {
                  field.onChange(files);
                }}
                {...(fileUploadProps || {})}
                btnLabel={btnLabel}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        );
      }}
    />
  );
};

export { FormFileUploadInput, FormFileImageInput };
