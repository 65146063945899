import * as React from "react";

const Kbd = (props: { kbd?: string }) => {
  if (!props.kbd) return null;
  return (
    <div className="peer-not-placeholder-shown:hidden pointer-events-none absolute inset-y-0 end-0 flex shrink-0 items-center justify-center pe-2 text-muted-foreground group-focus-within:hidden group-hover:hidden">
      <kbd className="inline-flex h-5 max-h-full items-center rounded border px-1 font-[inherit] text-[0.625rem] font-medium text-muted-foreground/70">
        {props.kbd}
      </kbd>
    </div>
  );
};

export { Kbd };
